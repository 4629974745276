import { motion, AnimatePresence } from 'framer-motion';
import { Scroll } from '@/commons';
import { hideBodyScroll, showBodyScroll, useDispatch } from '@/store';
import Modal from './components/modal.component';
import css from './modalContainer.module.scss';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

type Props = {
  children: any;
  isOpen: boolean;
  onClose: any;
  onOpen?: any;
};

const ModalContainer = (props: Props) => {
  const { children, isOpen, onClose, onOpen } = props;
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(hideBodyScroll());
    onOpen && onOpen();
  };

  const handleClose = () => {
    dispatch(showBodyScroll());
    onClose && onClose();
  };

  return (
    <>
      <AnimatePresence initial={false} exitBeforeEnter>
        {isOpen && (
          <Modal isOpen={isOpen} hasClose={false} onClose={handleClose} onOpen={handleOpen}>
            <motion.div
              key="modal"
              className={css[`modal-container`]}
              variants={dropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="c-modal-close" onClick={onClose} />

              <Scroll>
                <div className={css['modal-container-content']}>
                  <>{children}</>
                </div>
              </Scroll>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

ModalContainer.Header = Modal.Header;
ModalContainer.Body = Modal.Body;
ModalContainer.Footer = Modal.Footer;

export default ModalContainer;
