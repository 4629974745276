/** Utils */
import { useSelector, filtersInitialstate } from '@/store';

/** Config */
import { filterServerNames } from '@/config';

const antiquity = filterServerNames.ANTIQUITY_PART_FILTER;
const bodyPart = filterServerNames.BODY_PART_FILTER;
const edition = filterServerNames.EDITION_FILTER;
const family = filterServerNames.FAMILY_FILTER;
const id = filterServerNames.ID_FILTER;
const rarity = filterServerNames.RARITY_FILTER;
const robot = filterServerNames.ROBOT_FILTER;
const stats = filterServerNames.STATS_FILTER;
const status = filterServerNames.STATUS_FILTER;

export const useFiltersStatus = () => {
  const antiquityFilter = useSelector(store => store.filters[antiquity]);
  const bodyPartFilter = useSelector(store => store.filters[bodyPart]);
  const editionFilter = useSelector(store => store.filters[edition]);
  const familyFilter = useSelector(store => store.filters[family]);
  const idFilter = useSelector(store => store.filters[id]);
  const rarityFilter = useSelector(store => store.filters[rarity]);
  const robotFilter = useSelector(store => store.filters[robot]);
  const statsFilter = useSelector(store => store.filters[stats]);
  const statusFilter = useSelector(store => store.filters[status]);

  const filterStatus = {
    antiquity: antiquityFilter !== filtersInitialstate[antiquity],
    bodyPart: bodyPartFilter !== filtersInitialstate[bodyPart],
    edition: editionFilter !== filtersInitialstate[edition],
    family: familyFilter !== filtersInitialstate[family],
    id: idFilter !== filtersInitialstate[id],
    rarity: rarityFilter !== filtersInitialstate[rarity],
    robot: robotFilter !== filtersInitialstate[robot],
    stats: statsFilter !== filtersInitialstate[stats],
    status: statusFilter !== filtersInitialstate[status],
  };

  const numActiveFilters = Object.values(filterStatus).filter(o => o === true).length;

  return { filterStatus, numActiveFilters };
};
