import { createSlice } from '@reduxjs/toolkit';
import { elementToggle } from '@/commons/utils/array';

const initialState: any = {
  antiquity: '0',
  id: '',
  edition: ['basic', 'genesis', 'origin'],
  familyName: [],
  filtersCollapsed: [],
  numItems: 20,
  orderBy: 'createdAt',
  orderDirection: 'desc',
  owner: '',
  page: 0,
  partType: [],
  rarityName: [],
  robotName: [],
  stats: { attack: [0, 150], defense: [0, 150], health: [0, 150], speed: [0, 150] },
  status: ['0', '1', '2', '3','9'],
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    resetFilters() {
      return initialState;
    },
    setIdFilter(state, action) {
      state.id = action.payload;
    },
    setAntiquityFilter(state, action) {
      state.antiquity = action.payload;
    },
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    setFamilyFilter(state, action) {
      state.familyName = action.payload;
    },
    setNumItems(state, action) {
      state.numItems = action.payload;
    },
    setOrderByFilter(state, action) {
      const { key, sort } = action.payload;
      state.orderBy = key;
      state.orderDirection = sort;
    },
    setOwnerFilter(state, action) {
      state.owner = action.payload;
    },
    setPaginationFilter(state, action) {
      state.page = action.payload;
    },
    setPartFilter(state, action) {
      state.partType = action.payload;
    },
    setRarityFilter(state, action) {
      state.rarityName = action.payload;
    },
    setRobotFilter(state, action) {
      state.robotName = action.payload;
    },
    setStatsFilter(state, action) {
      const { key, values } = action.payload;
      state.stats[key] = values;
    },
    setStatsFilters(state, action) {
      state.stats = action.payload;
    },
    setStatusFilter(state, action) {
      state.status = action.payload;
    },
    setEditionFilter(state, action) {
      state.edition = action.payload;
    },
    toggleAntiquityFilter(state) {
      state.antiquity = '0';
    },
    toggleFilterCollapse(state, action) {
      state.filtersCollapsed = elementToggle(state.filtersCollapsed, action.payload);
    },
  },
});

export const {
  resetFilters,
  setIdFilter,
  setAntiquityFilter,
  setFamilyFilter,
  setFilters,
  setNumItems,
  setOrderByFilter,
  setOwnerFilter,
  setPaginationFilter,
  setPartFilter,
  setRarityFilter,
  setRobotFilter,
  setStatsFilter,
  setStatsFilters,
  setStatusFilter,
  setEditionFilter,
  toggleAntiquityFilter,
  toggleFilterCollapse,
} = filtersSlice.actions;

export const filtersInitialstate = { ...initialState };
export default filtersSlice.reducer;
