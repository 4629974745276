/** Vendors */
import { useId } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

/** Utils */
import { useInit, useScrollTop } from '@/hooks';

/** Components */
import { DevTools, ScrollTop } from '@/commons';
import Footer from './containers/footer/footer.container';
import Header from './containers/header/header.container';
import Activity from './pages/activity/activity';
import Inventory from './pages/inventory/inventory';
import BuyMon from './pages/buy-mon/buy-mon';
import UsersInventory from './pages/usersInventory/usersInventory';
import Marketplace from './pages/marketplace/marketplace';
import DetailPart from './pages/part-detail/partDetail';
import Profile from './pages/profile/profile';
import NotFound from './pages/notFound/notFound';

/** Styles */
import MedamonIcon from './assets/images/shared/medamonIcon.png';

const AppRouter = () => {
  useInit();
  const { scrollTop } = useScrollTop();

  const wallet = localStorage.getItem('wallet');

  const RequireAuth = ({ children, redirectTo }: { children: any; redirectTo: string }) => {
    return wallet ? children : <Navigate to={redirectTo} />;
  };

  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Marketplace />} />
        <Route path="/marketplace" element={<Marketplace />} />
        {/* <Route path="/buy" element={<BuyMon />} /> */}
        <Route
          path="/inventory"
          element={
            <RequireAuth redirectTo="/marketplace">
              <Inventory />
            </RequireAuth>
          }
        />
        <Route
          path="/activity"
          element={
            <RequireAuth redirectTo="/marketplace">
              <Activity />
            </RequireAuth>
          }
        />
        <Route path="/details/:id" element={<DetailPart />} />
        <Route
          path="/profile"
          element={
            <RequireAuth redirectTo="/marketplace">
              <Profile />
            </RequireAuth>
          }
        />
        <Route path="/users/:address" element={<UsersInventory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />

      <ScrollTop onClick={scrollTop} />

      <ToastContainer
        key={useId()}
        className="toast-container"
        toastClassName="dark-toast"
        icon={<img src={MedamonIcon} alt="medamon icon" />}
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {process.env.NODE_ENV === 'development' && <DevTools />}
    </>
  );
};

export default AppRouter;
