/**
@componentName Scroll
@description OverlayScrollbarsComponent Wrapper
*/

/** Vendors */
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

type Props = {
  children: any;
  isDisabled?: boolean;
};

const Scroll = (props: Props) => {
  const { children, isDisabled, ...rest } = props;

  return isDisabled === true ? (
    <>{children}</>
  ) : (
    <>
      <OverlayScrollbarsComponent {...rest}>{children}</OverlayScrollbarsComponent>
    </>
  );
};

export default Scroll;
