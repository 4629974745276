import CoreIcon from '@/assets/images/shared/pieces/coreIcon.png';
import LeftArmIcon from '@/assets/images/shared/pieces/leftArmIcon.png';
import RightArmIcon from '@/assets/images/shared/pieces/righArmIcon.png';
import LegsIcon from '@/assets/images/shared/pieces/legsIcon.png';
import HumanIcon from '@/assets/images/shared/families/humanIcon.png';
import SpiderIcon from '@/assets/images/shared/families/spiderIcon.png';
import FlyingIcon from '@/assets/images/shared/families/flyingIcon.png';

export const ANTIQUITY_PART_FILTER = 'antiquity';
export const BODY_PART_FILTER = 'bodyPart';
export const EDITION_FILTER = 'edition';
export const FAMILY_FILTER = 'family';
export const ID_FILTER = 'id';
export const RARITY_FILTER = 'rarity';
export const ROBOT_FILTER = 'robot';
export const STATS_FILTER = 'stats';
export const STATUS_FILTER = 'status';

export const enabledFilters = [
  BODY_PART_FILTER,
  EDITION_FILTER,
  FAMILY_FILTER,
  ID_FILTER,
  RARITY_FILTER,
  ROBOT_FILTER,
  STATS_FILTER,
  STATUS_FILTER,
];

export const filterServerNames = {
  ANTIQUITY_PART_FILTER: 'antiquity',
  BODY_PART_FILTER: 'partType',
  EDITION_FILTER: 'edition',
  FAMILY_FILTER: 'familyName',
  ID_FILTER: 'id',
  RARITY_FILTER: 'rarityName',
  ROBOT_FILTER: 'robotName',
  STATS_FILTER: 'stats',
  STATUS_FILTER: 'status',
};

export const BodyPartFilters = [
  {
    type: 'partType',
    key: 'core',
    img: CoreIcon,
    text: 'Core',
    value: 'core',
  },
  {
    type: 'partType',
    key: 'rightArm',
    img: RightArmIcon,
    text: 'Right Arm',
    value: 'rightArm',
  },
  {
    type: 'partType',
    key: 'leftArm',
    img: LeftArmIcon,
    text: 'Left Arm',
    value: 'leftArm',
  },
  {
    type: 'partType',
    key: 'legs',
    img: LegsIcon,
    text: 'Legs',
    value: 'legs',
  },
];

export const EditionFilters = [
  { value: ['basic', 'genesis', 'origin'], text: 'All' },
  { value: ['genesis'], text: 'Genesis' },
  { value: ['origin'], text: 'Origins' },
];

export const FamilyFilters = [
  {
    type: 'familyName',
    key: 'human',
    img: HumanIcon,
    text: 'Humans',
    value: 'Human',
  },
  {
    type: 'familyName',
    key: 'spider',
    img: SpiderIcon,
    text: 'Spiders',
    value: 'Spider',
  },
  {
    type: 'familyName',
    key: 'flying',
    img: FlyingIcon,
    text: 'Flyings',
    value: 'Flying',
  },
];

export const RarityFilters = [
  { type: 'rarityName', key: 'common', text: 'Common', value: 'Common' },
  {
    type: 'rarityName',
    key: 'uncommon',
    text: 'Uncommon',
    value: 'Uncommon',
  },
  { type: 'rarityName', key: 'rare', text: 'Rare', value: 'Rare' },
  { type: 'rarityName', key: 'epic', text: 'Epic', value: 'Epic' },
  {
    type: 'rarityName',
    key: 'legendary',
    text: 'Legendary',
    value: 'Legendary',
  },
];

export const RobotFilters: { key: string; text: string; value: string | null }[] = [
  { key: '', text: 'Select Medabot', value: '' },
  { key: 'mikazuki', text: 'Mikazuki', value: 'mikazuki' },
  { key: 'subotai', text: 'Subotai', value: 'subotai' },
  { key: 'necronmo', text: 'Necronmo', value: 'necronmo' },
  { key: 'sonnika', text: 'Sonnika', value: 'sonnika' },
  { key: 'leppux', text: 'Leppux', value: 'leppux' },
  { key: 'havoc', text: 'Havoc', value: 'havoc' },
  { key: 'sucubo', text: 'Sucubo', value: 'sucubo' },
  { key: 'gachala', text: 'Gachala', value: 'gachala' },
  { key: 'shinobi', text: 'Shinobi', value: 'shinobi' },
  { key: 'donnardo', text: 'Donnardo', value: 'donnardo' },
  { key: 'phalco', text: 'Phalco', value: 'phalco' },
  { key: 'olympus', text: 'Olympus', value: 'olympus' },
  { key: 'jetto', text: 'Jetto', value: 'jetto' },
  { key: 'geisha', text: 'Geisha', value: 'geisha' },
  { key: 'kabuto', text: 'Kabuto', value: 'kabuto' },
  { key: 'exyll', text: 'Exyll', value: 'exyll' },
  { key: 'sanctus', text: 'Sanctus', value: 'sanctus' },
  { key: 'antrox', text: 'Antrox', value: 'antrox' },
  { key: 'akakumo', text: 'Akakumo', value: 'akakumo' },
  { key: 'inferno', text: 'Inferno', value: 'inferno' },
  { key: 'octonaut', text: 'Octonaut', value: 'octonaut' },
  { key: 'qwerty', text: 'Qwerty', value: 'qwerty' },
  { key: 'tweezers', text: 'Tweezers', value: 'tweezers' },
  { key: 'w4sabi', text: 'W4sabi', value: 'w4sabi' },
  { key: 'saura', text: 'Saura', value: 'saura' },
  { key: 'colossus', text: 'Colossus', value: 'colossus' },
];

export const StatsFilters = [
  {
    type: 'stats',
    key: 'health',
    color: '#45FC81',
    text: 'Hit Points',
    range: [0, 150],
  },
  {
    type: 'stats',
    key: 'attack',
    color: '#FEE64C',
    text: 'Attack',
    range: [0, 150],
  },
  {
    type: 'stats',
    key: 'defense',
    color: '#45B1FC',
    text: 'Defense',
    range: [0, 150],
  },
  {
    type: 'stats',
    key: 'speed',
    color: '#FF65D0',
    text: 'Speed',
    range: [0, 150],
  },
];

export const StatusFilters = [
  { value: ['0', '1', '2', '3', '9'], text: 'All' },
  {
    value: ['0'],
    text: 'Not On Sale',
  },
  {
    value: ['1'],
    text: 'On Sale',
  },
  {
    value: ['2'],
    text: 'On Auction',
  },
  {
    value: ['3'],
    text: 'In Game',
  },
  {
    value: ['9'],
    text: 'In Maintenance',
  }
];
