import {
  TypedUseSelectorHook,
  useDispatch as useDispatchRR,
  useSelector as useSelectorRR,
} from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useDispatch = () => useDispatchRR<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRR;
export type { RootState };
