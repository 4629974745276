import { gql } from '@apollo/client';
import { client } from '../api/graphql';

const responseProps = `{
  id
  type
  user{id}
  to{id}
  tokenPart {
    id
    robotName
    familyName
    rarityName
    health
    attack
    defense
    speed
    imageURL
  }
  robot {
    id
    tokenPartOne {
      id
      robotName
      imageURL
    }
  }
  price
  createdAt
}`;

const userMovements = `
  query movements ($address: String) {
    movements(where: { user: $address }, orderBy: createdAt, orderDirection: desc) ${responseProps}
  }
`;

const movements = `
  query movements ($tokenPart: String) {
    movements(where: { tokenPart: $tokenPart }, orderBy: createdAt, orderDirection: desc) ${responseProps}
  }
`;

export const getMovements = (tokenPart: string) =>
  client
    .query({
      query: gql(movements),
      variables: {
        tokenPart: tokenPart,
      },
      fetchPolicy: 'no-cache',
    })
    .then((response: { data: any }) => response.data);

export const getUserMovements = (address: string) =>
  client
    .query({
      query: gql(userMovements),
      variables: {
        address: address,
      },
      fetchPolicy: 'no-cache',
    })
    .then((response: { data: any }) => response.data);
