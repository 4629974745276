import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import Medamon from '../abis/Medamon.json';
import MedaPart from '../abis/MedaPart.json';
import FactoryPart from '../abis/FactoryPart.json';
import MarketplaceFixedPrice from '../abis/MarketplaceFixedPrice.json';
import MarketplaceAuction from '../abis/MarketplaceAuction.json';
import OldMarketplaceFixedPrice from '../abis/OldMarketplaceFixedPrice.json';
import OldMarketplaceAuction from '../abis/OldMarketplaceAuction.json';
import CrystalPool from '../abis/CrystalPool.json';
import LockNFT from '../abis/LockNFT.json';
import {
  medamon,
  medaPart,
  factoryPart,
  marketplaceFixedPrice,
  marketplaceAuction,
  oldMarketplaceFixedPrice,
  oldMarketplaceAuction,
  feeBeneficiary,
  lockNFT,
  crystalPool,
  grpc,
} from '@/config';

import { fromBlockchain, toBlockchain } from '@/utils/formatMon';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: JSON.parse(grpc!),
      network: 'binance',
      qrcodeModalOptions: {
        mobileLinks: ['metamask', 'trust', 'math', 'safepal'],
      },
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  theme: 'dark',
  providerOptions,
});
export namespace Web3 {
  export const connectWallet = async () => {
    try {
      const web3 = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(web3);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();
      const address = accounts[0];

      // // Subscribe to accounts change
      // web3.on('accountsChanged', (accounts: any) => {
      //   console.log(accounts);
      // });

      // // Subscribe to chainId change
      // web3.on('chainChanged', (chainId: any) => {
      //   console.log(chainId);
      // });

      // // Subscribe to networkId change
      // web3.on('chainChanged', (networkId: any) => {
      //   console.log(networkId);
      // });

      return {
        address,
        web3,
        provider,
        signer,
      };
    } catch (error) {
      throw error;
    }
  };

  export const detectProvider = async () => {
    try {
      return web3Modal.cachedProvider ? await connectWallet() : null;
    } catch (error) {
      throw error;
    }
  };

  export const clearCacheProvider = () => web3Modal.clearCachedProvider();

  export const getConnectedContract = async (contractAddress: string, abi: any) => {
    const web3 = await connectWallet();
    const provider = web3.provider;
    const signer = web3.signer;

    const contract = new ethers.Contract(contractAddress, abi, provider);
    const connectedContract = contract.connect(signer);
    return connectedContract;
  };

  export const getAllowance = async (
    tokenContract: any,
    targetContract: string,
    wallet: string
  ): Promise<string> => {
    try {
      const allowance = await tokenContract.allowance(wallet, targetContract);
      return toBlockchain(allowance)!.toString();
    } catch (error: any) {
      throw error;
    }
  };

  export const signAddress = async (address: string) => {
    const web3 = await connectWallet();
    const signer = web3.signer;
    const signedMessage = await signer.signMessage(address);
    return { message: address, signedMessage };
  };

  export const getMonBalance = async (wallet?: string) => {
    try {
      const web3 = await connectWallet();
      const signer = web3.signer;

      const medamonContract = new ethers.Contract(medamon!, Medamon.abi, signer);
      const monBalance = await medamonContract.balanceOf(wallet);
      return fromBlockchain(monBalance)!.toString();
    } catch (error) {
      throw error;
    }
  };

  export const getNFTBalance = async (wallet?: any) => {
    try {
      const connectedNftContract = await getConnectedContract(medaPart!, MedaPart);

      const nftBalance = wallet
        ? await connectedNftContract.balanceOf(wallet)
        : await connectedNftContract.totalSupply();

      return nftBalance.toString();
    } catch (error: any) {
      throw error;
    }
  };

  export const getApprovals = async (
    allowed: string,
    tokenContract: any,
    target: string
  ): Promise<void> => {
    if (parseInt(allowed) < parseInt(toBlockchain(1000000)!.toString(), 10)) {
      const approved = await tokenContract.approve(target, toBlockchain(40000000));
      return await approved.wait();
    }
    return;
  };

  export const getNFTApproval = async (target: string, wallet: string): Promise<any> => {
    const connectedMedabots = await getConnectedContract(medaPart!, MedaPart);
    const hasApproval = await connectedMedabots.isApprovedForAll(wallet, target);
    if (!hasApproval) {
      const approves = await connectedMedabots.setApprovalForAll(target, true);
      return await approves.wait();
    }
    return hasApproval;
  };

  export const mintNFT = async (
    metadataSigned: {
      metadataURI: any;
      tuple: any;
      signature: string;
      idempotencyKey: string;
    },
    wallet: string
  ) => {
    try {
      const connectedMedabotsFactoryContract = await getConnectedContract(
        factoryPart!,
        FactoryPart
      );
      const connectedMedamon = await getConnectedContract(medamon!, Medamon.abi);
      const medamonAllowed = await getAllowance(connectedMedamon, feeBeneficiary!, wallet);

      await getApprovals(medamonAllowed, connectedMedamon, feeBeneficiary!);

      const minted = await connectedMedabotsFactoryContract.mint(
        metadataSigned.metadataURI,
        metadataSigned.tuple,
        metadataSigned.signature,
        metadataSigned.idempotencyKey
      );

      return await minted.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const depositMon = async (wallet:string, amount: number, signature:string, idempotencyKey:string, expirationBlock:number) => {
    try {
      const connectedCrystalPoolContract = await getConnectedContract(crystalPool!, CrystalPool);
      const connectedMedamon = await getConnectedContract(medamon!, Medamon.abi);
      const medamonAllowed = await getAllowance(connectedMedamon, crystalPool!, wallet);
      await getApprovals(medamonAllowed, connectedMedamon, crystalPool!);

      const deposited = await connectedCrystalPoolContract.deposit(medamon,amount,wallet,expirationBlock, signature,idempotencyKey);

      return await deposited.wait();
    } catch (error) {
      throw error;
    }
  };

  export const claimMon = async(amount: string, wallet: string, signature: string, idempotencyKey: string, expirationBlock:number) => {
    try {
      const connectedCrystalPoolContract = await getConnectedContract(crystalPool!, CrystalPool);
      const connectedMedamon = await getConnectedContract(medamon!, Medamon.abi);
      const medamonAllowed = await getAllowance(connectedMedamon, crystalPool!, wallet);
      await getApprovals(medamonAllowed, connectedMedamon, crystalPool!);
      
      const claimed = await connectedCrystalPoolContract.unLock(medamon, amount, wallet, expirationBlock, signature, idempotencyKey);

      return await claimed.wait();
    } catch (error) {
      throw error;
    }
  }

  export const listPart = async (id: string, price: any, wallet: string) => {
    try {
      const connectedMarketplace = getConnectedContract(
        marketplaceFixedPrice!,
        MarketplaceFixedPrice
      );
      await getNFTApproval(marketplaceFixedPrice!, wallet);

      const listed = await (
        await connectedMarketplace
      ).list(medaPart, parseInt(id, 10), toBlockchain(price));
      return await listed.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const listPartAuction = async (
    id: string,
    startPrice: number,
    duringDays: number,
    wallet: string
  ) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        marketplaceAuction!,
        MarketplaceAuction
      );

      await getNFTApproval(marketplaceAuction!, wallet);

      const listed = await connectedMarketplace.list(
        medaPart,
        parseInt(id, 10),
        toBlockchain(startPrice),
        duringDays * 24 * 60 * 60
      );
      return await listed.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const unlistPart = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        marketplaceFixedPrice!,
        MarketplaceFixedPrice
      );
      const unlisted = await connectedMarketplace.unlist(medaPart, parseInt(id, 10));
      return await unlisted.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const unlistPartForSecurity = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        oldMarketplaceFixedPrice!,
        OldMarketplaceFixedPrice
      );
      const unlisted = await connectedMarketplace.unlist(medaPart, parseInt(id, 10));
      return await unlisted.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const unlistPartAuction = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        marketplaceAuction!,
        MarketplaceAuction
      );
      const unlisted = connectedMarketplace.unlist(medaPart, parseInt(id, 10));
      return await unlisted.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const unlistPartAuctionForSecurity = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        oldMarketplaceAuction!,
        OldMarketplaceAuction
      );
      const unlisted = await connectedMarketplace.unlist(medaPart, parseInt(id, 10));
      return await unlisted.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const claimPart = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        marketplaceAuction!,
        MarketplaceAuction
      );
      const claimed = await connectedMarketplace.claim(medaPart, parseInt(id, 10));
      return await claimed.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const transferPart = async (address: any, id: string, wallet: string) => {
    try {
      const connectedPartsContract = await getConnectedContract(medaPart!, MedaPart);
      const transfered = await connectedPartsContract.transferFrom(
        wallet,
        address,
        parseInt(id, 10)
      );
      return await transfered.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const buyPart = async (part: { price: any; id: string }, wallet: string) => {
    try {
      const connectedMedamon = await getConnectedContract(medamon!, Medamon.abi);
      const connectedMarketplace = await getConnectedContract(
        marketplaceFixedPrice!,
        MarketplaceFixedPrice
      );

      const medamonAllowed = await getAllowance(connectedMedamon, marketplaceFixedPrice!, wallet);
      await getApprovals(medamonAllowed, connectedMedamon, marketplaceFixedPrice!);
      await getApprovals(medamonAllowed, connectedMedamon, feeBeneficiary!);

      const buyed = await connectedMarketplace.purchase(medaPart, parseInt(part.id, 10));
      return await buyed.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const makeAnOffer = async (id: string, amount: any, wallet: string) => {
    try {
      const connectedMedamon = await getConnectedContract(medamon!, Medamon.abi);
      const connectedMarketplace = await getConnectedContract(
        marketplaceAuction!,
        MarketplaceAuction
      );

      const medamonAllowed = await getAllowance(connectedMedamon, marketplaceAuction!, wallet);
      await getApprovals(medamonAllowed, connectedMedamon, marketplaceAuction!);
      await getApprovals(medamonAllowed, connectedMedamon, feeBeneficiary!);

      const purchaseRes = await connectedMarketplace.bid(
        medaPart,
        parseInt(id, 10),
        toBlockchain(amount)
      );
      return await purchaseRes.wait();
    } catch (error: any) {
      throw error;
    }
  };

  export const getBidStatus = async (id: string) => {
    try {
      const connectedMarketplace = await getConnectedContract(
        marketplaceAuction!,
        MarketplaceAuction
      );
      const bidStatus = await connectedMarketplace.listings(medaPart, parseInt(id, 10));
      return await bidStatus.wait();
    } catch (error: any) {
      return [];
    }
  };

  export const lockingNFT = async (id: string[], wallet: string) => {
    try {
      const ids = id.map(id => parseInt(id, 10));
      const connectedLockNFT = await getConnectedContract(lockNFT!, LockNFT);
      await getNFTApproval(lockNFT!, wallet);
      const locked = await connectedLockNFT.lock(medaPart, ids);
      return await locked.wait();
    } catch (error) {
      return 'Error locking NFT';
    }
  };

  export const unlockingNFT = async (id: string[], wallet: string) => {
    try {
      const ids = id.map(id => parseInt(id, 10));
      const connectedLockNFT = await getConnectedContract(lockNFT!, LockNFT);
      await getNFTApproval(lockNFT!, wallet);
      const unlocked = await connectedLockNFT.unlock(medaPart, ids);
      return await unlocked.wait();
    } catch (error) {
      return 'Error unlocking NFT';
    }
  };
}
