import { Permissions } from './general';
export * from './filters';
export * from './icons';
export * from './contracts';
export * from './urls';
export * from './grpc';
export * from './general';

export const navLinks = [
  // {
  //   label: 'The Game',
  //   path: 'https://www.medabots.game/',
  //   isExternal: true,
  // },
  {
    label: 'Marketplace',
    path: '/marketplace',
  },
  {
    label: 'Inventory',
    path: `/inventory`,
    restrictions: [Permissions.walletlogged],
  },
  {
    label: 'Activity',
    path: '/activity',
    restrictions: [Permissions.walletlogged],
  },
  {
    label: 'Game Account',
    path: '/profile',
    restrictions: [Permissions.walletlogged],
  },
  // {
  //   label: 'Buy MON',
  //   path: '/buy'
  // }
];

export const faqLinks = [
  {
    label: 'Terms & Conditions',
    path: 'https://medabots.game/app-medabots-terms-of-use/',
    isExternal: true,
  },
  {
    label: 'Privacy Policy',
    path: 'https://medabots.game/app-privacy-policy/',
    isExternal: true,
  },
  {
    label: 'Medamon',
    path: 'https://medabots.game/medamon/',
    isExternal: true,
  },
  {
    label: 'Whitepaper',
    path: 'https://medabots.game/wp-content/uploads/2022/07/Whitepaper-07-2022.pdf',
    isExternal: true,
  },
  {
    label: 'Team',
    path: 'https://medabots.game/the-team/',
    isExternal: true,
  },
  {
    label: 'Contact',
    path: 'https://medabots.game/contact/',
    isExternal: true,
  },
];
