import { capitalize } from '@/utils/strings';
import { RarityTag } from '@/components';
import PriceTag from '../priceTag/priceTag.component';
import { familyIcons, sass } from '@/config';
import css from './pieceCard.module.scss';

const PieceCard = (props: any) => {
  const {
    antiquity,
    attack,
    defense,
    edition,
    family,
    health,
    id,
    img,
    isOnSale,
    price,
    rarity,
    robot,
    speed,
    type,
  } = props;

  const fetchIcon = (family: string) => familyIcons.find(e => e.family === family);

  return (
    <div className={css['container']} style={{ borderColor: sass[`color-rarity-${rarity}`] }}>
      <div className={css['top']}>
        <RarityTag rarity={rarity} />
        <div className={css['id']}>{`ID #${id}`}</div>
      </div>

      <div className={css['body']}>
        <div className={css['family-icon']}>
          <img src={fetchIcon(family)?.icon} alt="family" />
        </div>

        <img className={css['piece-image']} src={img} alt="medapart" />
      </div>

      <div className={css['footer']}>
        <div className="flex between v-center">
          <div className={css['name']}>
            {type} / {capitalize(robot)}
          </div>
          <div className={css['antiquity']}>Antiquity: {antiquity}</div>
        </div>

        <div className={css['price']}>
          <PriceTag isOnSale={isOnSale} price={price} />
        </div>

        <div className="flex between v-align">
          <div className={css['edition']}>{edition !== 'basic' && edition?.toUpperCase()}</div>

          <div className={css['stats']}>
            <span className={css['health']}>{health}</span>
            <span className={css['attack']}>{attack}</span>
            <span className={css['defense']}>{defense}</span>
            <span className={css['speed']}>{speed}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieceCard;
