/**
@componentName InfinityScroll
@description HoC to provide infinity scroll
*/

/** Vendors */
import { useEffect, useRef } from 'react';
import { bool, node, func, InferProps } from 'prop-types';

/** Utils */
import { useDelay } from '@/hooks';

/** Types */
const propTypes = {
  children: node,
  getItems: func.isRequired,
  hasMoreItems: bool,
};

function isBottom(ref: React.RefObject<HTMLDivElement>) {
  if (!ref.current) {
    return false;
  }
  return ref.current.getBoundingClientRect().bottom <= window.innerHeight - 80;
}

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const InfinityScroll = (props: Props) => {
  const { children, getItems, hasMoreItems } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const run = useDelay(0);

  useEffect(() => {
    function handleScroll() {
      if (hasMoreItems && isBottom(contentRef)) {
        run(getItems);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMoreItems, getItems, run]);

  useEffect(() => {
    run(getItems);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div ref={contentRef}>
        <>{children}</>
      </div>
    </>
  );
};

InfinityScroll.propTypes = propTypes;
InfinityScroll.defaultProps = defaultPropTypes;

export default InfinityScroll;
