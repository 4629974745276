import Links from '../links/links.component';
import css from './menuDesktop.module.scss';

const MenuDesktop = () => {
  return (
    <>
      <div className={`${css['nav-desktop']} nav-desktop`}>
        <Links css={css} />
      </div>
    </>
  );
};

export default MenuDesktop;
