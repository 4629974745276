/** Vendors */
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

/** Utils */
import { useSelector } from '@/store';
import { getUserMovements } from '@/services/movements';

/** Styles */
import css from './activity.module.scss';
import link from '@/assets/images/shared/link.svg';
import { fromBlockchain } from '@/utils/formatMon';
import { bscscan } from '@/config';

// import res from '@/services/mocks/movementsUser.json';

const Activity = () => {
  const wallet = useSelector(store => store.user.wallet)?.toLowerCase();
  const [isLoading, setIsLoading] = useState(false);
  const [movements, setMovements]: any = useState([]);

  const formatMessage = (movement: any): any => {
    let message = 'you attempted to ';
    switch (movement.type) {
      case 'bid':
        message += `bid on <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'purchase':
        message += `purchase <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'unlist':
        message += `cancel the sell of <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'list':
        message += `start a sale on <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'listAuction':
        message += `start an auction for <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'mintTokenPart':
        message += `buy <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'transferRobotPart':
        message += `transfer <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
      case 'unlistAuction':
        message += `cancel the auction of <b>MEDAPART #${movement.tokenPart.id}</b>`;
        break;
    }
    return movement.price ? `${message} FOR <b>${fromBlockchain(movement.price)} MON</b>` : message;
  };

  const renderMovements = useMemo(() => {
    let day = '';

    return !movements
      ? []
      : movements.reduce((acc: any, movement: any) => {
          const newDay = format(new Date(movement.createdAt * 1000), 'MMM dd, yyyy');

          acc.push(
            <>
              {newDay !== day && (
                <>
                  {/* <div className={css.day} /> */}
                  <div className={css.date}>{newDay}</div>
                </>
              )}
              <div className={css.movement}>
                <div className={css.hour}>
                  {format(new Date(movement.createdAt * 1000), 'HH:mm')}
                </div>
                <div className={css.transaction}>
                  <div
                    className={css.message}
                    dangerouslySetInnerHTML={{ __html: formatMessage(movement) }}
                  />
                  <div className={css.url}>
                    <a
                      href={`${bscscan}/${movement.id.split('-')[0]}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      View transaction status here
                    </a>
                    <img src={link} alt="" />
                  </div>
                </div>
              </div>
            </>
          );

          if (newDay !== day) day = newDay;

          return acc;
        }, []);
  }, [movements]);

  useEffect(() => {
    const userMovements = async () => {
      try {
        setIsLoading(true);
        const res = await getUserMovements(wallet);
        setMovements(res.movements);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    wallet && userMovements();
  }, [wallet]);

  return (
    <main className={css.activity}>
      <h1>Activities</h1>
      {!isLoading && (
        <div className={css.activities}>
          {movements.length > 0 ? (
            renderMovements
          ) : (
            <div className={css.message}>The user has no movements</div>
          )}
        </div>
      )}
    </main>
  );
};

export default Activity;
