import { Link } from 'react-router-dom';
import styles from './sitemap.module.scss';

const SitemapRoutes = [
  // {
  //   label: 'MON',
  //   path: 'https://www.medabots.game/medamon/',
  //   isExternal: true,
  // },
  {
    label: 'THE GAME',
    path: 'https://www.medabots.game/',
    isExternal: true,
  },
];

const Sitemap = () => {
  return (
    <div className={styles['sitemap']}>
      <ul className={styles['ul']}>
        {SitemapRoutes.map(route => {
          return route.isExternal ? (
            <li key={route.label} className={styles['li']}>
              <a
                key={`sitemap_${route.label}`}
                className={styles['link']}
                href={route.path}
                target="_blank"
                rel="noreferrer"
              >
                {route.label}
              </a>
            </li>
          ) : (
            <li key={route.label} className={styles['li']}>
              <Link key={`sitemap_${route.label}`} className={styles['link']} to={route.path}>
                {route.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sitemap;
