import { fetchGQL } from '@/api/graphql';

const queryName = 'tokenParts';

const responsePropsDefault = `{
  id
  tokenUri
  creator {
    id
  }
  owner {
    id
  }
  previousOwner {
    id
  }
  robotName
  partType
  rarityName
  familyName
  imageURL
  attack
  defense
  speed
  health
  price
  edition
  isOnSale
  createdAt
  updatedAt
}`;

const variablesConfig: object = {
  id: {type: 'String'},
  attack_gte: { type: 'BigInt!' },
  attack_lte: { type: 'BigInt!' },
  defense_gte: { type: 'BigInt!' },
  defense_lte: { type: 'BigInt!' },
  familyName: { type: '[String!]', name: 'familyName_in' },
  first: { type: 'Int', basic: true },
  health_gte: { type: 'BigInt!' },
  health_lte: { type: 'BigInt!' },
  orderBy: { type: 'BigInt!', basic: true },
  orderDirection: { type: 'String!', basic: true },
  owner: { type: 'String!' },
  partType: { type: '[String!]', name: 'partType_in' },
  rarityName: { type: '[String!]', name: 'rarityName_in' },
  robotName: { type: '[String!]', name: 'robotName_in' },
  skip: { type: 'Int', basic: true },
  speed_gte: { type: 'BigInt!' },
  speed_lte: { type: 'BigInt!' },
  status: { type: '[String]', name: 'status_in' },
  edition: { type: '[String]', name: 'edition_in' },
};

export const getParts = async (
  variables = {},
  responseProps = responsePropsDefault,
  options = {}
) =>
  fetchGQL({
    options,
    queryName,
    responseProps,
    variables,
    variablesConfig,
  });
