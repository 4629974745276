import { createSlice } from '@reduxjs/toolkit';
import { getProfile } from '@/services/users';

const initialState = { wallet: '' };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(_, action) {
      return action.payload;
    },
    setWallet(state, action) {
      state.wallet = action.payload;
    },
  },
});

export const getUser = () => async (dispatch: any, getState: any) => {
  const result = await getProfile();

  const newuser = {
    ...getState().user,
    name: result.name,
    email: result.email,
    balance: result.balance,
  };

  dispatch(setUser(newuser));
};

export const { setUser, setWallet } = userSlice.actions;
export default userSlice.reducer;
