export enum Robots {
  mikazuki = 'Mikazuki',
  subotai = 'Subotai',
  necronmo = 'Necronmo',
  sonnika = 'Sonnika',
  leppux = 'Leppux',
  havoc = 'Havoc',
  sucubo = 'Sucubo',
  gachala = 'Gachala',
  shinobi = 'Shinobi',
  donnardo = 'Donnardo',
  phalco = 'Phalco',
  olympus = 'Olympus',
  jetto = 'Jetto',
  geisha = 'Geisha',
  kabuto = 'Kabuto',
  exyll = 'Exyll',
  sanctus = 'Sanctus',
  antrox = 'Antrox',
  akakumo = 'Akakumo',
  inferno = 'Inferno',
  octonaut = 'Octonaut',
  qwerty = 'Qwerty',
  tweezers = 'Tweezers',
  w4sabi = 'W4sabi',
  saura = 'Saura',
  colossus = 'Colossus',
}

export enum PartsNames {
  'core' = 'Core',
  'rightArm' = 'Right Arm',
  'leftArm' = 'Left Arm',
  'legs' = 'Legs',
}

export enum Family {
  human = 'Human',
  spider = 'Spider',
  flying = 'Flying',
}

export enum Rarity {
  common = 'Common',
  uncommon = 'Uncommon',
  rare = 'Rare',
  epic = 'Epic',
  legendary = 'Legendary',
}

export const Rarities = Object.keys(Rarity);

export enum RarityColor {
  common = '#E9E8E8',
  uncommon = '#81D73C',
  rare = '#00BFFF',
  epic = '#CF61EA',
  legendary = '#EA8E21',
}

export const rarities: any = [
  { name: 'common', color: RarityColor.common },
  { name: 'uncommon', color: RarityColor.uncommon },
  { name: 'rare', color: RarityColor.rare },
  { name: 'epic', color: RarityColor.epic },
  { name: 'legendary', color: RarityColor.legendary },
];
