/**
@componentName useScrollTop
@description Hook to Scroll at the top every change in location
*/

/** Vendors */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTop = () => {
  const { pathname } = useLocation();
  const scrollTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    setTimeout(() => scrollTop(), 0);
  }, [pathname]);

  return { scrollTop };
};

export default useScrollTop;
