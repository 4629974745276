/** Vendors */
import { useEffect, useState } from 'react';

/** Utils */
import {
  RootState,
  useDispatch,
  useSelector,
  getBalance,
  getFilteredParts,
  resetFilters,
  resetParts,
  setFilters,
  setOwnerFilter,
} from '@/store';

/** Components */
import PiecesList from '@/containers/piecesList/piecesList.component';
import { Button } from '@/commons';
import Filters from '@/containers/filters/filters.component';
import MintPartModal from './components/mintPartModal';

/** Styles */
import css from './inventory.module.scss';

const Inventory = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((store: RootState) => store.user.wallet)?.toLowerCase();

  const [visibleMintModal, setVisibleMintModal] = useState(false);

  const onMint = () => {
    setTimeout(() => {
      dispatch(getFilteredParts());
      dispatch(getBalance());
    }, 5000);
  };

  useEffect(() => {
    dispatch(resetFilters());
    dispatch(resetParts());

    if (wallet) {
      dispatch(setFilters({ numItems: 1000, owner: wallet }));
      setTimeout(() => dispatch(getFilteredParts()), 500);
    }

    return () => {
      dispatch(setOwnerFilter(''));
    };
  }, [dispatch, wallet]);

  return (
    <main className={css['inventory']}>
      <div className={css['filters']}>
        <Filters />
      </div>

      <div className={css['main']}>
        <div className={css['actions']} hidden={!wallet}>
          <Button text="Mint medapart" onClick={() => setVisibleMintModal(true)} />
        </div>
        <PiecesList owner={wallet} />
      </div>

      <MintPartModal
        isOpen={visibleMintModal}
        onClose={() => setVisibleMintModal(false)}
        onAction={onMint}
        wallet={wallet}
      />
    </main>
  );
};

export default Inventory;
