//@ts-ignore
import { sass } from '@/config';

export const isDesktop = () => {
  return window.matchMedia(`(min-width: ${sass.minDesktopScreen}px)`).matches;
};

export const isMobile = () => {
  return window.matchMedia(`(max-width: ${sass.maxMobileScreen}px)`).matches;
};

export const isTouchDevice = () => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
};
