/**
@componentName Checkbox
@description Uncontrolled Checkbox
*/

/** Vendors */
import { useEffect, useState } from 'react';
import { bool, func, node, string, InferProps } from 'prop-types';

/** Styles */
import css from './checkbox.module.scss';

/** Types */
const propTypes = {
  checked: bool,
  label: node,
  onToggle: func,
  value: string,
};

const defaultPropTypes = {
  checked: false,
  value: '',
};

type Props = InferProps<typeof propTypes>;

const Checkbox = (props: Props) => {
  const { label, onToggle, value } = props;
  const [checked, setChecked] = useState(props.checked);

  /** Handlers */
  const handleToggle = () => {
    setChecked(!checked);
    onToggle && onToggle(value);
  };

  /** Init */
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <>
      <div className={css.container}>
        <div className={css['input-container']}>
          <label>
            <input type="checkbox" onClick={handleToggle} />
            <svg
              className={`${css.check} ${checked ? css.active : ''}`}
              aria-hidden="true"
              viewBox="0 0 15 11"
              fill="none"
            >
              <path d="M1 4.5L5 9L14 1" strokeWidth="2" stroke={checked ? '#01DBFE' : 'none'} />
            </svg>
          </label>
        </div>
        <div className={css['label-container']}>
          {label && (
            <div className={css['label']}>
              <>{label}</>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultPropTypes;

export default Checkbox;
