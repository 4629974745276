import { useEffect, useState } from 'react';
import { Button } from '@/commons';
import { getUser, useDispatch } from '@/store';
import toast from '@/utils/customToast';
import MedamonIcon from '@/assets/images/shared/medamonIcon.png';
import CrystalIcon from '@/assets/images/shared/crystal.png';
import styles from './profileAccountStatus.module.scss';
import RowBalanceComponent from './components/rowBalance.component';
import ClaimMonModal from './components/claimMonModal.component';
import ClaimCrystalModal from './components/claimCrystalModal.component';

type Props = {
  user: any;
};

const ProfileAccountStatus = (props: Props) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [monBalance, setMonBalance] = useState('0.00');
  const [crystalsBalance, setCrystalsBalance] = useState('0');

  const [isOpenBuy, setIsOpenBuy] = useState(false);
  const [isOpenClaim, setIsOpenClaim] = useState(false);

  const handleClose = () => {
    setIsOpenBuy(false);
    setIsOpenClaim(false);
  };

  const handleOpenBuy = () => setIsOpenBuy(true);
  const handleOpenClaim = () => setIsOpenClaim(true);

  const handleRefreshBalance = async () => {
    try {
      await dispatch(getUser());
      toast.success('Updated User balance');
    } catch {}
  };

  useEffect(() => {
    if (user.balance) {
      setMonBalance(user.balance?.gmonSplitted.poker);
      setCrystalsBalance(user.balance?.crystals);
    }
  }, [user.balance]);

  return (
    <div className={styles.container}>
      <RowBalanceComponent
        icon={MedamonIcon}
        text="MON in game"
        balance={user.email === '' ? '0.00' : monBalance}
        button={<Button text={'Claim'} onClick={handleOpenClaim} disabled={user.email === ''} />}
      />

      <RowBalanceComponent
        icon={CrystalIcon}
        text="Crystals"
        balance={user.email === '' ? '0' : crystalsBalance}
        button={<Button text={'Claim'} onClick={handleOpenBuy} disabled={user.email === ''} />}
      />

      <ClaimMonModal
        isOpen={isOpenClaim}
        onClose={handleClose}
        onClaimMon={() => {}}
        wallet={user.wallet}
      />

      <ClaimCrystalModal
        isOpen={isOpenBuy}
        onClose={handleClose}
        onClaimCrystals={() => {}}
        wallet={user.wallet}
      />
    </div>
  );
};

export default ProfileAccountStatus;
