import { useTranslation } from 'react-i18next';
import { sass } from '@/config';
import css from './pieceRow.module.scss';

import Stats from '../stats/stats.component';
import Tag from '../tag/tag.component';

import FlyingIcon from '@/assets/images/shared/families/flyingIcon.png';
import HumaIcon from '@/assets/images/shared/families/humanIcon.png';
import SpiderIcon from '@/assets/images/shared/families/spiderIcon.png';

import CoreIcon from '@/assets/images/shared/pieces/coreIcon.png';
import LeftArmIcon from '@/assets/images/shared/pieces/leftArmIcon.png';
import RightArmIcon from '@/assets/images/shared/pieces/righArmIcon.png';
import LegsIcon from '@/assets/images/shared/pieces/legsIcon.png';

import { RarityTag } from '@/components';
import PriceTag from '../priceTag/priceTag.component';
import { getCardsImage } from '../../utils/getImages.util';
import { capitalize } from '@/utils/strings';

const familyIcons = [
  { item: 'human', icon: HumaIcon },
  { item: 'spider', icon: SpiderIcon },
  { item: 'flying', icon: FlyingIcon },
];

const pieceIcons = [
  { item: 'core', icon: CoreIcon },
  { item: 'leftArm', icon: LeftArmIcon },
  { item: 'rightArm', icon: RightArmIcon },
  { item: 'legs', icon: LegsIcon },
];

const PieceRow = (props: any) => {
  const {
    rarity,
    life,
    speed,
    attack,
    defense,
    type,
    robot,
    family,
    img,
    id,
    antiquity,
    price,
    isOnSale,
    edition,
  } = props;
  const { t } = useTranslation();

  const fetchIcon = (object: any[], target: any) =>
    object.find((e: { item: any }) => e.item === target);

  const cards = getCardsImage(robot, type);

  return (
    <>
      <div className={css['container']} style={{ borderColor: sass[`color-rarity-${rarity}`] }}>
        <div className={css['left']}>
          <div className={css['image-wrapper']}>
            <img className={css['piece-image']} src={img} alt="medapart" />
          </div>

          <div className={css['data']}>
            <div className={css['top']}>
              <RarityTag rarity={rarity} />
              <div className={css['id']}>{`ID #${id}`}</div>
              <div className={css['antiquity']}>Antiquity: {antiquity}</div>
            </div>

            <div className={css['price']}>
              <PriceTag price={price} isOnSale={isOnSale} />
            </div>
          </div>
        </div>

        <div className={css['right']}>
          <div className={css['info']}>
            <div className={css['name']}>
              {t(type)} / {capitalize(robot)}
            </div>
            <div className={css['data']}>
              <div className={css['icons']}>
                <Tag img={fetchIcon(familyIcons, family).icon} txt={capitalize(family)} />
                <Tag img={fetchIcon(pieceIcons, type).icon} txt={t(type)} />
                <div className={css['edition']}>
                  {edition === 'basic' || edition === '' ? '' : edition.toUpperCase()}
                </div>
              </div>

              <div className={css['stats']}>
                <Stats health={life} speed={speed} attack={attack} defense={defense} />
              </div>
            </div>
          </div>
          <div className={css['cards']}>
            {cards.map((url: string, index: number) => (
              <img className={css.card} key={index} src={url} alt="card" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PieceRow;
