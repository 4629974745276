import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Links from '../links/links.component';
import { faqLinks } from '@/config';
import css from './menuMobile.module.scss';

const MenuMobile = () => {
  const inputRef: any = useRef<HTMLElement>();
  const [open, setOpen]: any = useState();
  const [page, setPage] = useState(0);

  const handleClose = () => {
    setOpen(false);
    inputRef.current.checked = false;
  };

  useEffect(() => {
    open && document.body.classList.add('noscroll');
    if (open === false) {
      setPage(0);
      setTimeout(() => document.body.classList.remove('noscroll'), 500);
    }
  }, [open]);

  useEffect(() => {
    return handleClose();
  }, []);

  return (
    <>
      <nav className={`${css['nav-mobile']} nav-mobile`} role="navigation">
        <div className="toggle">
          <input type="checkbox" ref={inputRef} onClick={() => setOpen(!open)} />
          <span></span>
          <span></span>
          <span></span>

          <div className="links">
            <div className="pages" style={{ transform: `translateX(-${page * 100}%)` }}>
              <div className="page">
                <div className="list">
                  <Links css={css} onClick={handleClose} />
                </div>

                <div className="more" onClick={() => setPage(1)}>
                  <ul>
                    <li>
                      {/* eslint-disable-next-line */}
                      <a>
                        MORE &nbsp;&nbsp; <label className="icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="page">
                <div className="list">
                  <ul>
                    {faqLinks.map((route: any) => {
                      return route.isExternal ? (
                        <li key={route.label} className={css.li}>
                          <a
                            key={`sitemap_${route.label}`}
                            className={css.link}
                            href={route.path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {route.label}
                          </a>
                        </li>
                      ) : (
                        <li key={route.label} className={css.li}>
                          <NavLink key={`menu_${route.label}`} className={css.link} to={route.path}>
                            {route.label}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="more" onClick={() => setPage(page - 1)}>
                  <ul>
                    <li>
                      {/* eslint-disable-next-line */}
                      <a>
                        <label className="icon" style={{ transform: 'rotate(-90deg)' }} />{' '}
                        &nbsp;&nbsp; BACK
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="bg" onMouseUp={handleClose} />
        </div>
      </nav>
    </>
  );
};

export default MenuMobile;
