/**
 * @componentName Input
 * @description Uncontrolled Input
 */

/** Vendors */
import React, { forwardRef, useEffect } from 'react';
import {
  any,
  bool,
  func,
  InferProps,
  object,
  oneOf,
  oneOfType,
  node,
  number,
  string,
} from 'prop-types';

/** Styles */
import css from './input.module.scss';

/** Types */
import { Event } from '@/config/types/index';

const propTypes = {
  /** Autocomplete */
  autocomplete: oneOf(['on', 'off']).isRequired,
  /** Aditional Class Container */
  className: string,
  /** Ref Input */
  cref: any,
  /** Disabled */
  disabled: bool,
  /** Focus on Load */
  focusOnLoad: bool,
  /** Id */
  id: string,
  /** Label */
  label: oneOfType([string, node]),
  /** Name */
  name: string,
  /** Handler Blur */
  onBlur: func,
  /** Handler Change*/
  onChange: func,
  /** Handler Click */
  onClick: func,
  /** Handler Enter Key */
  onEnter: func,
  /** Handler Focus */
  onFocus: func,
  /** Placeholder */
  placeholder: string,
  /** Read Only */
  readonly: bool,
  /** Required */
  required: bool,
  /** Aditional Style Container */
  style: object,
  /** Aditional Style Input */
  styleInput: object,
  /** Aditional Style Label */
  styleLabel: object,
  /** Type */
  type: string.isRequired,
  /** Default Value */
  value: oneOfType([string, number]).isRequired,
};

const defaultPropTypes = {
  autocomplete: 'off',
  className: '',
  type: 'text',
  value: '',
};

type Props = InferProps<typeof propTypes>;

const Input = forwardRef<Props, any>((props: Props, ref: any) => {
  const {
    autocomplete,
    className,
    cref,
    disabled,
    focusOnLoad,
    id,
    label,
    name,
    onBlur,
    onChange,
    onClick,
    onEnter,
    onFocus,
    placeholder,
    readonly,
    required,
    style,
    styleLabel,
    styleInput,
    type,
    value,
    ...rest
  } = props;
  const selfRef = cref || ref;

  /** Handlers */
  const handleOnKeyPressEnter = (e: React.KeyboardEvent) => {
    if (onEnter && (e.key === 'Enter' || e.key === 'NumpadEnter')) {
      e.preventDefault();
      e.stopPropagation();
      onEnter(e);
    }
  };

  const handleOnBlur = (e: Event) => {
    onBlur && onBlur(e);
  };

  const handleOnChange = (e: Event) => {
    onChange && onChange(e);
  };

  const handleOnClick = (e: Event) => {
    onClick && onClick(e);
  };

  const handleOnFocus = (e: Event) => {
    onFocus && onFocus(e);
  };

  useEffect(() => {
    if (focusOnLoad) {
      const input = selfRef.current;
      if (!input) return;
      input.scrollIntoView({ block: 'center', behavior: 'smooth' });
      input.select();
    }
  }, [focusOnLoad, selfRef]);

  return (
    <>
      <div className={`c-input ${css['input-container']} ${className}`} {...(style && { style })}>
        {label && (
          <label {...(styleLabel && { style: styleLabel })}>
            <>{label}</>
          </label>
        )}

        <input
          {...rest}
          autoComplete={autocomplete}
          {...(disabled && { disabled })}
          {...(id && { id })}
          {...(name && { name })}
          {...(placeholder && { placeholder })}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          {...(onEnter && { onKeyPress: handleOnKeyPressEnter })}
          onKeyPress={handleOnKeyPressEnter}
          onFocus={handleOnFocus}
          onClick={handleOnClick}
          {...(readonly && { readOnly: readonly })}
          ref={selfRef}
          {...(required && { required })}
          {...(styleInput && { style: styleInput })}
          type={type}
          {...(value && { value })}
        />
      </div>
    </>
  );
});

Input.propTypes = propTypes;
Input.defaultProps = defaultPropTypes;

export default Input;
