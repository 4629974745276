import { useSelector } from '@/store';
import css from './filterContainer.module.scss';

const FilterContainer = (props: any) => {
  const { children, filterName } = props;
  const filtersCollapsed: Array<string> = useSelector(store => store.filters.filtersCollapsed);

  return (
    <div
      className={`${css.container} ${
        filterName && filtersCollapsed.includes(filterName) && css['filter-collapsed']
      }`}
    >
      {children}
    </div>
  );
};

FilterContainer.Title = (props: any) => {
  const { children, onClick } = props;

  return (
    <div className={css['filter-title']} onClick={onClick}>
      <div className={`filter-white ${css['filter-collapse-button']}`} />
      {children}
    </div>
  );
};

FilterContainer.Body = (props: any) => <div className={css['filter-body']}>{props.children}</div>;

export default FilterContainer;
