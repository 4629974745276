/** Vendors */
import { useState } from 'react';

/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';
import SellForm from './sellForm/sellForm.component';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

interface SellState {
  type: string;
  id: number;
  price?: number;
  startPrice?: number;
  duringDays?: number;
}

type Props = {
  isOpen: boolean;
  partId: string;
  onClose: any;
  onSell: Function;
  wallet: string;
};

const SellModal = (props: Props) => {
  const { isOpen, onClose, onSell, partId, wallet } = props;
  const [sellPrice, setSellPrice] = useState<SellState>({ type: '', id: 0 });
  const handleSubmit = (e: any) => setSellPrice(e);
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleSell = async () => {
    try {
      toast.info('Listing part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (sellPrice.type === 'fixedPrice') {
        if (wallet) await Web3.listPart(partId, sellPrice.price, wallet);
        partEvent(GA_ACTION.LIST_FIXED, GA_LABEL.SUCCESS);
      } else {
        if (wallet)
          await Web3.listPartAuction(partId, sellPrice.startPrice!, sellPrice.duringDays!, wallet);
        partEvent(GA_ACTION.LIST_AUCTION, GA_LABEL.SUCCESS);
      }

      toast.success('Part listed!', {
        position: 'bottom-right',
        theme: 'colored',
      });
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      partEvent(GA_ACTION.LIST, GA_LABEL.FAILURE);
    } finally {
      onSell();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`Choose your sale method for medapart #${partId}?`}</Modal.Header>
        <Modal.Body>
          <SellForm id={partId} cb={handleSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button text="Sell medapart" onClick={handleSell} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SellModal;
