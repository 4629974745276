import css from './copyright.module.scss';
import Icon from '@/assets/images/footer/icon.png';

const Copyright = () => {
  return (
    <div className={css.copyright}>
      <img src={Icon} alt="Medabots" />
      <span className={css.text}>&copy; Comadran Studios, {new Date().getFullYear()}</span>
    </div>
  );
};

export default Copyright;
