/** Utils */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBalance, setBalance, setUser, useDispatch, useSelector } from '@/store';
import { getShortAddress } from '@/utils/formatAddress';
import toast from '@/utils/customToast';

/** Component */
import DropdownProfile from '@/components/dropdownProfile/dropdownProfile.component';
import { Button } from '@/commons';
import Modal from '@/components/modal/modal.component';

/** Styles */
import css from './loginButton.module.scss';
import LoginIcon from '@/assets/images/header/loginIcon.png';
import MedamonIcon from '@/assets/images/shared/medamonIcon.png';
import { hasProfile } from '@/services/users';
import { AnimatePresence } from 'framer-motion';
import { Web3 } from '@/services/web3';

const LoginButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const balance = useSelector((store: any) => store.balance);
  const wallet = useSelector((store: any) => store.user.wallet);

  const connect = async () => {
    try {
      const result = await Web3.connectWallet();
      dispatch(setUser({ wallet: result.address }));
      localStorage.setItem('wallet', result.address);
      const balance = await Web3.getMonBalance(result.address);
      dispatch(setBalance(parseFloat(balance).toFixed(2)));

      const hasProfileInGame: boolean = await hasProfile(result.address);
      if (hasProfileInGame) navigate('/marketplace');
      setModalOpen(!hasProfileInGame);
    } catch (error: any) {
      toast.error('Something went wrong!', { position: 'bottom-right' });
      localStorage.clear();
      return;
    }
  };

  const close = () => {
    setModalOpen(false);
    navigate('/marketplace');
  };

  const redirectToProfile = () => {
    setModalOpen(false);
    navigate('/profile');
  };

  useEffect(() => {
    Web3.detectProvider().then(async result => {
      if (!result) {
        toast.warning('No address detected. Check your wallet.', { position: 'bottom-right' });
        localStorage.clear();
        return;
      }
      const balance = await Web3.getMonBalance(result.address);
      dispatch(setBalance(parseFloat(balance).toFixed(2)));
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBalance(wallet));
  }, [dispatch, wallet]);

  return (
    <>
      <div className={css.container}>
        <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
          {modalOpen && (
            <Modal
              action={{ text: 'Create User', handleAction: redirectToProfile }}
              handleClose={close}
              text="If you want to use your NFTs in game you’ll have to create a Medabots Game Account. You can create it in a moment here and enjoy the advantages of the medabots game."
              title="Create Medabots Game Account"
            />
          )}
        </AnimatePresence>
        {wallet ? (
          <div className={css.logged}>
            {/* <Notification /> */}
            <DropdownProfile />
            <div className={css.account}>
              <span className={css.wallet}>{getShortAddress(wallet)}</span>
              <span className={css.balance}>
                {balance} <img src={MedamonIcon} alt="medamon" />
              </span>
            </div>
          </div>
        ) : (
          <>
            <Button
              className={css['login-button']}
              onClick={connect}
              text="Login"
              variant="secondary"
            >
              <img src={LoginIcon} alt="login" />
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default LoginButton;
