import { useCallback, useMemo, useState } from 'react';
import Modal from '@/commons/modal/components/modal.component';
import { hideBodyScroll, showBodyScroll, useDispatch } from '@/store';
import { isDesktop } from '@/commons/utils/device';
import css from './cards.module.scss';

type Props = {
  cards: any;
};

const Cards = (props: Props) => {
  const { cards } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpen = useCallback(() => {
    if (!isDesktop()) return;
    dispatch(hideBodyScroll());
    setIsOpen(true);
  }, [dispatch]);

  const handleModalClose = () => {
    dispatch(showBodyScroll());
    setIsOpen(false);
  };

  const renderCards = useMemo(
    () =>
      cards.map((url: string, idx: number) => (
        <picture className={css['card']} key={idx} onClick={handleModalOpen}>
          <img src={url} alt="card" />
        </picture>
      )),
    [handleModalOpen, cards]
  );

  return (
    <>
      <div className={`${css['container']} ${cards.length > 1 ? css['multiple'] : ''}`}>
        <div className={css['cards-list']}>{renderCards}</div>
      </div>

      <Modal className={'modal-cards'} isOpen={isOpen} onClose={handleModalClose}>
        <div className={css['modal']}>{renderCards}</div>
      </Modal>
    </>
  );
};

export default Cards;
