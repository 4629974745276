import { useEffect, useState } from 'react';
import { isDesktop } from '@/commons/utils/device';

import Banner from './components/banner/banner.component';
import Menu from './components/menu/menu.component';
import TabBar from './components/tabs/tabs.component';

import css from './header.module.scss';

const Header = () => {
  const [scrollY, setScrollY] = useState(0);
  const [fixated, setFixated] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(220);
  const [menuHeight, setMenuHeight] = useState(72);

  useEffect(() => {
    function handleScroll() {
      const offset = window.scrollY;

      if (offset > bannerHeight - menuHeight) {
        setFixated(true);
      } else {
        setScrollY(offset);
        setFixated(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bannerHeight, menuHeight]);

  useEffect(() => {
    function handleResize() {
      if (isDesktop()) {
        setBannerHeight(220);
        setMenuHeight(72);
      } else {
        setBannerHeight(132);
        setMenuHeight(60);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={css.container}>
      <Menu />
      <Banner fixated={fixated} scrollY={scrollY} />
      <TabBar fixated={fixated} />
    </div>
  );
};

export default Header;
