import { motion } from 'framer-motion';
import Backdrop from '../backdrop/backdrop.component';

import styles from './modal.module.scss';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const Modal = ({
  action,
  handleClose,
  text,
  title,
}: {
  action: {text:any, handleAction:any};
  handleClose: any;
  title: string;
  text: any;
}) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={e => e.stopPropagation()}
        className={`${styles.modal} ${styles['orange-gradient']}`}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className={styles.container}>
          <div className={styles.payload}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.actions}>
            <button className={styles['modal-button']} onClick={action.handleAction}>
              {action.text}
            </button>
            <button className={styles['modal-button']} onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
