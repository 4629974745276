/** Utils */
import toast from '@/utils/customToast';

/** Styles */
import css from './about.module.scss';
import CopyIcon from '@/assets/images/shared/copyIcon.png';

type Props = {
  user: any;
};

const About = (props: Props) => {
  const { user } = props;

  return (
    <>
      <div className={css['container']}>
        <div className={css['info']}>
          <span className={css['info-title']}>About</span>

          <div className={css['info-content']}>
            <div className={`${css['item']}`}>
              <div className={css['info-content-title']}>owner</div>
              <div className={css['info-content-text']}>ExName</div>
            </div>
            <div className={`${css['item']}`}>
              <div className={css['info-content-title']}>address</div>
              <div className={css['info-content-text']}>
                {user}{' '}
                <span
                  className={css['copy-icon']}
                  onClick={() => {
                    navigator.clipboard.writeText(user);
                    toast.success('Copied to clipboard', { position: 'bottom-right' });
                  }}
                >
                  <img src={CopyIcon} alt="copyIcon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
