/**
@componentName RarityTag
@description Tag showing rarity of items
*/

/** Vendors */
import { object, string, InferProps } from 'prop-types';

/** Constants */
import { rarities } from '@/config';

/** Styles */
import css from './rarityTag.module.scss';

/** Types */
const propTypes = { rarity: string.isRequired, style: object };

type Props = InferProps<typeof propTypes>;
const defaultPropTypes = {};

const RarityTag = (props: Props) => {
  const { rarity, style } = props;
  const rarityProps = rarities.find((e: any) => e.name === rarity.toLowerCase());

  return (
    <div className={`${css['rarity-tag']} ${css[rarity.toLowerCase()]}`} style={style!}>
      <div className={css['text']}>{rarityProps?.name}</div>
    </div>
  );
};

RarityTag.propTypes = propTypes;
RarityTag.defaultProps = defaultPropTypes;

export default RarityTag;
