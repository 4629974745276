/**
@componentName Button
@description Button with included debounce
*/

/** Vendors */
import { useRef } from 'react';
import { any, bool, func, node, number, object, oneOf, string, InferProps } from 'prop-types';

/** Styles */
import css from './button.module.scss';

/** Types */
const propTypes = {
  /** Button's children */
  children: node,
  /** Ref Button */
  cref: any,
  /** Aditional Class Container */
  className: string,
  /** Number of miliseconds to debounce */
  debounce: number.isRequired,
  /** Disabled */
  disabled: bool,
  /** Callback */
  onClick: func,
  /** Aditional Style */
  style: object,
  /** Text */
  text: string,
  /** Style Variant */
  variant: oneOf(['', 'primary', 'secondary']),
};

const defaultPropTypes = {
  className: '',
  debounce: 1000,
  text: '',
  variant: '',
};

type Props = InferProps<typeof propTypes>;

const Button = (props: Props) => {
  const { className, children, cref, debounce, disabled, onClick, style, text, variant, ...rest } =
    props;
  const timeout: any = useRef();

  const handleClick = () => {
    if (typeof timeout.current === 'undefined') {
      onClick && onClick();
      timeout.current = setTimeout(() => {
        timeout.current = clearTimeout(timeout.current);
      }, debounce);
    }
  };

  return (
    <>
      <button
        {...rest}
        className={`${css['button-container']} c-button ${variant} ${className}`}
        onClick={handleClick}
        {...(disabled && { disabled })}
        ref={cref}
        {...(style && { style: style })}
      >
        <>{children}</>
        {text && <span className={`text ${children && 'text-spaced'}`}>{text}</span>}
      </button>
    </>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultPropTypes;

export default Button;
