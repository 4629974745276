export enum GA_CATEGORY {
  PART = 'part',
  ROBOT = 'robot',
}

export enum GA_ACTION {
  LIST = 'list',
  UNLIST = 'unlist',

  LIST_FIXED = 'list-fixed',
  UNLIST_FIXED = 'unlist-fixed',

  LIST_AUCTION = 'list-auction',
  UNLIST_AUCTION = 'unlist-auction',

  MINT = 'mint',
  BUY = 'buy',
  CLAIM = 'claim',
  BID = 'bid',
  TRANSFER = 'transfer',
}

export enum GA_LABEL {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
