import { useEffect, useState } from 'react';
import { Button, Input } from '@/commons';
import { signIn, signUp } from '@/services/auth';
import toast from '@/utils/customToast';
import styles from './profileUserData.module.scss';
import { getUser, useDispatch, setUser } from '@/store';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

type Props = {
  user: any;
};

const ProfileDataUser = (props: Props) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null);
  const [userData, setUserData] = useState({ name: '', email: '', password: '' });

  const handleTogglePassword = () => setPasswordShown(state => !state);

  const handleSignUp = async () => {
    try {
      const newUser = {
        email: userData.email.toLowerCase(),
        password: userData.password,
        username: userData.name,
      };
      const result = await signUp(newUser);

      if (result.success === true) {
        toast.success('Successfully registered. Check your email to confirm your account.', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Something went wrong', { position: 'bottom-right' });
    }
  };

  const handleSignIn = async () => {
    try {
      const loginUser = {
        email: userData.email.toLowerCase(),
        password: userData.password,
      };

      const result = await signIn(loginUser);

      if (result.success === true) {
        localStorage.setItem('token', result.data.accessToken);
        setIsLoggedIn(true);
        toast.success('Successfully signed in', { position: 'bottom-right' });
      }
    } catch (e) {
      toast.error('Invalid email or password', { position: 'bottom-right' });
    }
  };

  const handleSignOut = () => {
    try {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      dispatch(setUser({ wallet: user.wallet }));
      setUserData({ name: '', email: '', password: '' });
      toast.success('Successfully signed out', { position: 'bottom-right' });
    } catch (e) {
      toast.error('Something went wrong', { position: 'bottom-right' });
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setUserData({ ...userData, name: value });
        break;
      case 'email':
        setUserData({ ...userData, email: value });
        break;
      case 'password':
        setUserData({ ...userData, password: value });
        break;
      default:
        break;
    }

    e.preventDefault();
  };

  useEffect(() => {
    setUserData({ ...userData, name: user.name, email: user.email });
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    isLoggedIn && user.wallet && !user.email && dispatch(getUser());
  }, [dispatch, isLoggedIn, user]);

  return (
    <div className={styles.container}>
      {isLoggedIn && (
        <div className={styles['input-container']}>
          <label className={styles.label}>{`Welcome ${user.name ? user.name : ''}`}</label>
        </div>
      )}

      <div className={styles['input-container']}>
        <label className={styles.label}>Email</label>
        <Input
          className={styles.input}
          name={'email'}
          disabled={isLoggedIn}
          defaultValue={userData.email}
          onChange={handleChange}
        />
      </div>

      {!isLoggedIn && (
        <div className={styles['input-container']}>
          <label className={styles.label}>Password</label>
          <div className={styles.password}>
            <Input
              className={`${styles.input}`}
              name={'password'}
              type={passwordShown ? 'text' : 'password'}
              defaultValue={userData.password || ''}
              onChange={handleChange}
            />
            <div className={styles['icon-password']} onClick={handleTogglePassword}>
              {passwordShown ? (
                <EyeOutlined style={{ fontSize: '2.5rem' }} />
              ) : (
                <EyeInvisibleOutlined style={{ fontSize: '2.5rem' }} />
              )}
            </div>
          </div>
        </div>
      )}

      {!isLoggedIn && (
        <div
          className={styles['button-container']}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Button onClick={handleSignIn}>Login</Button>

          <Button onClick={handleSignUp}>Create User</Button>
        </div>
      )}

      {isLoggedIn && (
        <div
          className={styles['button-container']}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Button onClick={handleSignOut}>Logout</Button>
        </div>
      )}
    </div>
  );
};

export default ProfileDataUser;
