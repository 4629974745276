import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { graphApi } from '@/config';

export const client = new ApolloClient({
  uri: graphApi,
  cache: new InMemoryCache(),
});

const formTypesGQL = (variables: any = {}, variablesConfig: any = {}) => {
  const variablesGQL = Object.keys(variables).reduce((acc: string, v: any) => {
    const newVar = variables[v] && variablesConfig[v] ? `$${v}:${variablesConfig[v].type},` : '';
    return acc + newVar;
  }, '');

  return variablesGQL ? '(' + variablesGQL + ')' : '';
};

const formFiltersGQL = (variables: any = {}, variablesConfig: any = {}) => {
  const filtersGQL = Object.keys(variables).reduce(
    (acc: any, v: any) => {
      if (variables[v] && variablesConfig[v]) {
        const name = variablesConfig[v]?.name ? variablesConfig[v].name : v;
        if (variablesConfig[v].basic) {
          return { ...acc, basic: `${acc.basic} ${name}: $${v},` };
        } else {
          return { ...acc, where: `${acc.where} ${name}: $${v},` };
        }
      }
      return acc;
    },
    { basic: '', where: '' }
  );

  return filtersGQL.basic || filtersGQL.where
    ? `(where: {${filtersGQL.where}}, ${filtersGQL.basic})`
    : '';
};

const formGQL = (
  queryName: string,
  variables: object = {},
  variablesConfig: object = {},
  responseProps: string
) => `
  query ${queryName}
  ${formTypesGQL(variables, variablesConfig)}
  {
    ${queryName} 
    ${formFiltersGQL(variables, variablesConfig)}
    ${responseProps}
  }`;

type Props = {
  queryName: string;
  variables?: object;
  variablesConfig?: object;
  responseProps?: string;
  options?: any;
};

export const fetchGQL = async (props: Props) => {
  const {
    options = {},
    queryName,
    responseProps = '',
    variables = {},
    variablesConfig = {},
  } = props;

  const result = await client.query({
    query: gql(formGQL(queryName, variables, variablesConfig, responseProps)),
    variables,
    fetchPolicy: options.fetchPolicy || 'no-cache',
  });

  return result.data;
};
