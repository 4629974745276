import auth from '../api/auth.api';

interface ISignIn {
  email: string;
  password: string;
}

interface ISignUp {
  email: string;
  password: string;
  username: string;
}

export const signUp = (body: ISignUp) =>
  auth.post('users/create', body).then((response: { data: any }) => response.data);

export const signIn = (body: ISignIn) =>
  auth.post('users/signin', body).then((response: { data: any }) => response.data);
