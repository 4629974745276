import { ethers } from 'ethers';

export const toBlockchain = (value: any) => {
  try {
    return ethers.utils.parseEther(value ? value.toString() : '0');
  } catch (error) {}
};
export const fromBlockchain = (value: any) => {
  try {
    return ethers.utils.formatEther(value ? value.toString() : '0');
  } catch (error) {}
};
