import { isDesktop } from '@/commons/utils/device';
import MenuDesktop from './components/menuDesktop/menuDesktop.component';
import MenuMobile from './components/menuMobile/menuMobile.component';
import LoginButton from './components/loginButton/loginButton.component';
import css from './menu.module.scss';

const Menu = () => {
  return (
    <div className={css.menu}>
      {isDesktop() && <MenuDesktop />}
      {!isDesktop() && <MenuMobile />}
      <LoginButton />
    </div>
  );
};

export default Menu;
