import { useState } from 'react';
import { setOrderByFilter, useDispatch } from '@/store';
import css from './dropDownButton.module.scss';

type Props = {
  onFilter: Function;
};

const DropdownButton = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState('Last Listed');
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = (value: any, text: string) => {
    setActiveFilter(text);

    dispatch(setOrderByFilter({ key: value.key, sort: value.sort }));
    onFilter && onFilter();
  };

  const handleIsOpen = () => setIsOpened(true);

  return (
    <div className={css['content']}>
      <label className={css['dropdown']}>
        <div
          onClick={handleIsOpen}
          className={!isOpened ? css['dd-button'] : css['dd-button-opened']}
        >
          {activeFilter}
        </div>

        <input type="checkbox" className={css['dd-input']} id="test" />

        <ul className={css['dd-menu']}>
          <li onClick={() => handleClick({ key: 'createdAt', sort: 'desc' }, 'Last Listed')}>
            Last Listed
          </li>
          <li onClick={() => handleClick({ key: 'createdAt', sort: 'asc' }, 'First Listed')}>
            First Listed
          </li>
          <li onClick={() => handleClick({ key: 'price', sort: 'desc' }, 'Highest Price')}>
            Highest Price
          </li>
          <li onClick={() => handleClick({ key: 'price', sort: 'asc' }, 'Lowest Price')}>
            Lowest Price
          </li>
        </ul>
      </label>
    </div>
  );
};

export default DropdownButton;
