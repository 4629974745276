import { useNavigate } from 'react-router-dom';
import css from './backButton.module.scss';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`${css['container']} c-back-button`}>
        <div className={css['back-button']} onClick={() => navigate(-1)}>
          <span className={`${css.icon} filter-electric-blue`} />
          <span className={css.text}>Back</span>
        </div>
      </div>
    </>
  );
};

export default BackButton;
