/**
 * Returns Array with Added or Deleted passed Element
 * @param {array} Array of elements
 * @param {element} String|Number
 * @example
 *
 * elementToggle([1, 2, 3], 4) -> [1, 2, 3, 4]
 * elementToggle([1, 2, 3], 3) -> [1, 2]
 */

export function elementToggle(array, element = '') {
  if (!Array.isArray(array) || !element) return array;
  let newArray = [...array];
  let elementIndex = newArray.indexOf(element);

  if (elementIndex !== -1) {
    newArray.splice(elementIndex, 1);
  } else {
    newArray.push(element);
  }
  return newArray;
}
