/**
@componentName Modal
@description Modal Component
*/

/** Vendors */
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { bool, node, func, InferProps, string } from 'prop-types';

/** Styles */
import css from './modal.module.scss';

/** Types */
const propTypes = {
  /** Modal's children */
  children: node,
  /** Custom classname */
  className: string,
  /** Id's Modal */
  id: string,
  /** Open visibility control */
  isOpen: bool.isRequired,
  /** Open callback */
  onOpen: func,
  /** Close callback */
  onClose: func.isRequired,
  /** Disable Close Button */
  hasClose: bool,
};

const defaultPropTypes = {
  hasClose: true,
};

type Props = InferProps<typeof propTypes>;

const Modal = (props: Props) => {
  const { children, className, hasClose, isOpen, onClose, onOpen } = props;
  const element: any = useRef(document.createElement('div'));
  const keybindings: any = useRef(document.onkeydown);

  /** Handlers */
  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    document.body.classList.add('noscroll');
    onOpen && onOpen();
  }, [onOpen]);

  /** Init */
  useLayoutEffect(() => {
    const ref = element.current;
    document.body.appendChild(ref);

    return () => {
      document.body.classList.remove('noscroll');
      document.body.removeChild(ref);
    };
  }, []);

  useEffect(() => {
    const bindings = keybindings.current;

    document.onkeydown = e => {
      e = e || window.event;
      if (e.key === 'Escape') handleClose();
    };

    return () => {
      document.onkeydown = bindings;
    };
  }, [handleClose]);

  useEffect(() => {
    isOpen && handleOpen();
  }, [isOpen, handleOpen]);

  return !isOpen
    ? null
    : createPortal(
        <div className={`${css['modal']} ${className} c-modal`}>
          <div className={`${css['modal-bg']} c-modal-bg`} onClick={handleClose} />
          <div className={`${css['modal-content']} c-modal-content`}>
            {hasClose && (
              <div className={`${css['modal-close']} c-modal-close`} onClick={handleClose} />
            )}
            <>{children}</>
          </div>
        </div>,
        element.current
      );
};

Modal.Header = ({ children }: any) => (
  <div className={`${css['modal-header']} c-modal-header`}>{children}</div>
);
Modal.Body = ({ children }: any) => (
  <div className={`${css['modal-body']} c-modal-body`}>{children}</div>
);
Modal.Footer = ({ children }: any) => (
  <div className={`${css['modal-footer']} c-modal-footer`}>{children}</div>
);

Modal.propTypes = propTypes;
Modal.defaultProps = defaultPropTypes;

export default Modal;
