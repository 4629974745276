/**
@componentName ScrollTop
@description ScrollTop Button
*/

import { useEffect, useState } from 'react';
import css from './scrollTop.module.scss';

type Props = {
  onClick?: Function;
};

const ScrollTop = (props: Props) => {
  const { onClick } = props;
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    onClick && onClick();
  };

  useEffect(() => {
    function handleScroll() {
      const offset = window.scrollY;

      if (offset > 60) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div
        className={`${css.scrollTop} ${showButton ? 'visible' : ''} c-scrollTop`}
        onClick={handleClick}
      >
        <span className="icon" />
      </div>
    </>
  );
};

export default ScrollTop;
