import { useState } from 'react';
import styles from './auction.module.scss';

interface IProps {
  id: string;
  handleFixed?: any;
  handleAuction?: any;
}
interface AuctionState {
  startPrice: number;
  duringDays: number;
}

const Auction = (props: IProps) => {
  const { id, handleAuction } = props;
  const type = 'auction';
  const [auction, setAuction] = useState<AuctionState>({ startPrice: 0, duringDays: 1 });

  const handlePrice = (e: any) => {
    const value = e.target.value;
    setAuction({ ...auction, startPrice: value });
    handleAuction({ id, type, ...{ ...auction, startPrice: value } });
    e.preventDefault();
  };

  const handleTime = (e: any) => {
    const value = e.target.value;
    const _value = isNaN(parseInt(value)) || parseInt(value) <= 0 ? '1' : value;
    setAuction({ ...auction, duringDays: _value });
    handleAuction({ id, type, ...{ ...auction, duringDays: _value } });
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <p>
        For auctions, you can set the price to increase or decrease over time. Once it reaches the
        final price, it’ll stay on the market at this price. In order to get it back, you’ll have to
        cancel the sale.
      </p>
      <div className={styles['form']}>
        <div className={styles['input-container']}>
          <div className={styles.label}>START AT:</div>
          <input
            className={styles.input}
            type="number"
            placeholder="Introduce your price (MON)"
            onChange={handlePrice}
          />
        </div>
        <div className={styles['input-container']}>
          <div className={styles.label}>DURING DAYS:</div>
          <input
            className={styles.input}
            type="number"
            placeholder="1 day"
            onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
            onChange={handleTime}
          />
        </div>
      </div>
    </div>
  );
};

export default Auction;
