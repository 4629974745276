import { createSlice } from '@reduxjs/toolkit';

const initialState = { bodyScroll: true };

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    hideBodyScroll(state) {
      state.bodyScroll = false;
      document.body.classList.add('noscroll');
    },
    showBodyScroll(state) {
      state.bodyScroll = true;
      document.body.classList.remove('noscroll');
    },
  },
});

export const { hideBodyScroll, showBodyScroll } = uiSlice.actions;
export default uiSlice.reducer;
