import { getWinnerAuctions } from '@/services/auction';
import { toBlockchain } from '@/utils/formatMon';

export const getAuctionWins = async (address: string | undefined): Promise<any> => {
  try {
    const data = await getWinnerAuctions(address!, toBlockchain(Date.now() / 1000)!.toString());
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
