export namespace Error {
  export const defaultError = () => 'Something went wrong!';

  export const handleError = (err: string) => {
    switch (err) {
      case 'execution reverted: MARKETPLACE: Caller is not token owner':
        return 'Check the connection of your wallet';
      case 'MetaMask Tx Signature: User denied transaction signature.':
        return 'Transaction reverted by user';
      case 'execution reverted: ERC20: transfer amount exceeds balance':
        return "You don't have enough tokens";
      case 'execution reverted: ERC20: transfer amount exceeds allowance':
        return "You don't have enough tokens";
      case 'execution reverted: ERROR: sender is in the blacklist':
        return 'Please, contact the administrator';

      default:
        return defaultError();
    }
  };
}
