/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal, Space } from '@/commons';

/* Consts */
import axiosInstance from '@/api/services.api';
import { factoryPart, GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  onClose: any;
  onAction: any;
  wallet: string;
};

const MinPartModal = (props: Props) => {
  const { isOpen, onClose, onAction, wallet } = props;
  const gaEventTracker = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleForge = async () => {
    toast.info(`We are forging your medapart`, {
      position: 'bottom-right',
      theme: 'colored',
    });

    try {
      const signature = await axiosInstance.post('/mint', {
        contract: factoryPart,
      });

      const metadataSigned = signature.data.data;
      await Web3.mintNFT(metadataSigned, wallet);
      toast.success(`Medapart forged`, {
        position: 'bottom-right',
        theme: 'colored',
      });
      gaEventTracker(GA_ACTION.MINT, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );
      gaEventTracker(GA_ACTION.MINT, GA_LABEL.FAILURE);
    } finally {
      onAction();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`YOU ARE GOING TO FORGE A MEDAPART`}</Modal.Header>
        <Modal.Body>
          <>
            <p>
              To forge a medapart is to mint it. The type, stats and rarity will be randomly
              generated. Once the minting is done, you will be notified and the medapart will appear
              in your inventory.
            </p>
            Remember that you will need enough MON (5) to obtain your medapart.
          </>
          <Space space={32} />
        </Modal.Body>
        <Modal.Footer>
          <Button text="Buy" onClick={handleForge} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MinPartModal;
