import { baseImage } from '@/config';

export const getRobotImage = (name: string) => {
  return name ? `${baseImage}/robots/${name}.png` : '';
};

export const getCardsImage = (robot: string, type: string): any => {
  const baseUrl = `${baseImage}/cards/english`;
  switch (type) {
    case 'core':
      return [`${baseUrl}/${robot}_0_1.png`, `${baseUrl}/${robot}_0_2.png`];
    case 'rightArm':
      return [`${baseUrl}/${robot}_1.png`];
    case 'leftArm':
      return [`${baseUrl}/${robot}_2.png`];
    case 'legs':
      return [`${baseUrl}/${robot}_3.png`];
    default:
      return [];
  }
};
