import { createSlice } from '@reduxjs/toolkit';
import { Family, PartsNames, Rarities, Robots } from '@/config';
import { getParts } from '@/config/services/tokenParts';

const initialState: object[] = [];

const partsSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {
    resetParts() {
      return initialState;
    },
    setParts(_, action) {
      return action.payload;
    },
  },
});

export const { resetParts, setParts } = partsSlice.actions;

export const getFilteredParts = () => async (dispatch: any, getState: any) => {
  const filterId = getState().filters.id;
  const filterPartType = getState().filters.partType;
  const filterRarityName = getState().filters.rarityName;
  const filterFamilyName = getState().filters.familyName;
  const filterRobotName = getState().filters.robotName;
  const filterStats = getState().filters.stats;
  const filterAntiquity = getState().filters.antiquity;
  const filterStatus = getState().filters.status;
  const filterEdition = getState().filters.edition;
  const filterOrderBy = getState().filters.orderBy;
  const filterOrderDirection = getState().filters.orderDirection;
  const filterOwner = getState().filters.owner;
  const page = getState().filters.page;
  const numItems = getState().filters.numItems;

  const filters: any = {
    attack_gte: filterStats.attack[0],
    attack_lte: filterStats.attack[1],
    defense_gte: filterStats.defense[0],
    defense_lte: filterStats.defense[1],
    health_gte: filterStats.health[0],
    health_lte: filterStats.health[1],
    speed_gte: filterStats.speed[0],
    speed_lte: filterStats.speed[1],
    robotName: filterRobotName.length > 0 ? filterRobotName : Object.keys(Robots),
    partType: filterPartType.length > 0 ? filterPartType : Object.keys(PartsNames),
    rarityName: filterRarityName.length > 0 ? filterRarityName : Rarities,
    familyName: filterFamilyName.length > 0 ? filterFamilyName : Object.keys(Family),
    owner: filterOwner,
    createdAt: filterAntiquity,
    status: filterStatus,
    edition: filterEdition,
    orderBy: filterOrderBy,
    orderDirection: filterOrderDirection,
    skip: page * numItems,
    first: numItems,
  };

  if (filterId.length > 0) filters.id = filterId;
  else delete filters.id;

  try {
    const pieces = await getParts(filters);
    dispatch(setParts(pieces.tokenParts));
  } catch (error) {}
};

export default partsSlice.reducer;
