import { Link } from 'react-router-dom';
import css from './tabs.module.scss';

import Pieces from '@/assets/images/shared/pieces/legsIcon.png';
import Robots from '@/assets/images/shared/robotIcon.png';

const Tab = (props: any) => {
  return (
    <div className={css['tab']}>
      {props?.data.map((data: { icon: any; text: any }) => (
        <Link key={data.icon} to={`/marketplace#${data.icon}`}>
          <img className={css['icon']} src={data.icon === 'pieces' ? Pieces : Robots} alt="tabs" />
          <span className={css['text']}>{data.text}</span>
        </Link>
      ))}
    </div>
  );
};

type Props = {
  fixated: boolean;
};

const Tabs = (props: Props) => {
  const { fixated } = props;

  return (
    <div className={`${css.container} ${fixated && css.sticky}`}>
      <Tab
        data={[
          { icon: 'pieces', text: 'medaparts' },
          // { icon: 'robots', text: 'robots' },
        ]}
      />
    </div>
  );
};

export default Tabs;
