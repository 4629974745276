import { useState } from 'react';
import css from './viewSelector.module.scss';

const ShowList = (props: any) => {
  const [selected, setSelected] = useState('list');

  const handleShow = (e: any) => {
    setSelected(e.target.value);
    props.onSelectView(e.target.value);
  };

  return (
    <>
      <div className={css['container']}>
        <button
          className={`${css['show-list-button']} ${selected === 'list' ? css.selected : ''}`}
          onClick={handleShow}
          value="list"
          aria-label="Show List"
        />
        <button
          className={`${css['show-rows-button']} ${selected === 'rows' ? css.selected : ''}`}
          onClick={handleShow}
          value="rows"
          aria-label="Show Rows"
        />
      </div>
    </>
  );
};

export default ShowList;
