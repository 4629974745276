/* Utils */
import toast from '@/utils/customToast';

/* Components */
import { Button, Modal } from '@/commons';
import axiosInstance from '@/api/services.api';
import { Web3 } from '@/services/web3';

type Props = {
  isOpen: any;
  onClose: any;
  onClaimMon: Function;
  wallet: string;
};

const ClaimMonModal = (props: Props) => {
  const { isOpen, onClose, onClaimMon, wallet } = props;

  const handleClaimMon = async () => {
    try {
      toast.info('Claiming Mon...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      const signature = await axiosInstance.post('/claim', {
        wallet: wallet,
      });

      if (wallet)
        await Web3.claimMon(
          signature.data.data.medamon,
          wallet,
          signature.data.data.signature,
          signature.data.data.idempotencyKey,
          signature.data.data.expirationBlock
        );

      toast.success('Mon claimed!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      onClose();
    } catch (error) {
      console.log(error);
      toast.error('Error claiming Mon', {
        position: 'bottom-right',
        theme: 'colored',
      });

      onClose();
    } finally {
      onClaimMon();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`CLAIM YOUR MONS`}</Modal.Header>
        <Modal.Body>
          If you claim the MON you've won inside the game, you will add it to your wallet and you
          will be able use it in the marketplace
        </Modal.Body>
        <Modal.Footer>
          <Button text={`Claim`} onClick={handleClaimMon} />
          <Button text={`Cancel`} onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimMonModal;
