/**
@componentName Chip
@description
*/

/** Vendors */
import { func, string, InferProps } from 'prop-types';

/** Styles */
import css from './chip.module.scss';

/** Types */
import { Event } from '@/config/types/index';

const propTypes = {
  /** Handler Click */
  onClick: func,
  /** Text */
  text: string,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const Chip = (props: Props) => {
  const { onClick, text } = props;

  /** Handlers */
  const handleOnClick = (e: Event) => {
    onClick && onClick(e);
  };

  return (
    <>
      <div
        className={`${css.container} c-chip ${onClick && 'c-chip-with-action'}`}
        onClick={handleOnClick}
      >
        <span className={css.text}>{text}</span>
        {onClick && <div className={`${css['action']} ${css['close']} c-chip-action`} />}
      </div>
    </>
  );
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultPropTypes;

export default Chip;
