import { isMobile } from '@/commons/utils/device';
import css from '../profileAccountStatus.module.scss';

type IProps = {
  icon: string;
  text: string;
  balance: string;
  button?: any;
};

const RowBalanceComponent = (props: IProps) => {
  const { icon, text, balance, button } = props;
  return (
    <>
      <div className={css.row}>
        <div className={`${css.row} ${css.left}`}>
          <img style={{ height: '5rem' }} src={icon} alt="icon" />
          {!isMobile() && <span style={{ paddingLeft: '1.5rem' }}>{text}</span>}
        </div>
        <div className={`${css.row} ${css.center}`}>{balance}</div>
        {button && <div className={`${css.row} ${css.right}`}>{button}</div>}
      </div>
    </>
  );
};

export default RowBalanceComponent;
