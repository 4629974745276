import { Web3 } from '@/services/web3';
import { createSlice } from '@reduxjs/toolkit';

const initialState = '0.00';

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setBalance(_, action) {
      return action.payload;
    },
  },
});

export const { setBalance } = balanceSlice.actions;
export const getBalance =
  (wallet?: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      const balance = await Web3.getMonBalance(wallet);
      dispatch(setBalance(parseFloat(balance).toFixed(2)));
    } catch (error) {}
  };

export default balanceSlice.reducer;
