/** Vendors */
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Utils */
import { getAntiquity } from '@/utils/dates';
import { fromBlockchain } from '@/utils/formatMon';

/** Components */
import PieceCard from '@/components/pieceCard/pieceCard.component';
import PieceRow from '@/components/pieceRow/pieceRow.component';

/** Styles */
import css from './list.module.scss';

type Props = {
  pieces: any;
  showCards: boolean;
};

const List = (props: Props) => {
  const { pieces, showCards } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={css['list']}>
        {showCards ? (
          <div className={css['grid']}>
            {pieces.map(
              ({
                attack,
                createdAt,
                defense,
                edition,
                familyName,
                health,
                id,
                imageURL,
                isOnSale,
                partType,
                price,
                rarityName,
                robotName,
                speed,
              }: any) => (
                <Link key={id} to={`/details/${id}`}>
                  <PieceCard
                    attack={attack}
                    antiquity={getAntiquity(createdAt)}
                    defense={defense}
                    edition={edition}
                    family={familyName}
                    health={health}
                    id={id}
                    img={imageURL}
                    isOnSale={isOnSale}
                    price={fromBlockchain(price)}
                    rarity={rarityName}
                    robot={robotName}
                    speed={speed}
                    type={t(partType)}
                  />
                </Link>
              )
            )}
          </div>
        ) : (
          <div className={css['rows']}>
            {pieces.map((piece: any) => (
              <Link key={piece.id} to={`/details/${piece.id}`}>
                <PieceRow
                  antiquity={getAntiquity(piece.createdAt)}
                  attack={parseInt(piece.attack)}
                  id={piece.id}
                  defense={parseInt(piece.defense)}
                  edition={piece.edition}
                  family={piece.familyName}
                  img={piece.imageURL}
                  isOnSale={piece.isOnSale}
                  life={parseInt(piece.health)}
                  part={piece.part}
                  price={fromBlockchain(piece.price)}
                  rarity={piece.rarityName}
                  robot={piece.robotName}
                  speed={parseInt(piece.speed)}
                  type={piece.partType}
                />
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default List;
