import { toast } from 'react-toastify';
import { css } from 'glamor';

const defaultOptions: any = {
  autoClose: 3000,
  position: 'bottom-right',
  closeOnClick: true,
  hideProgressBar: true,
  newestOnTop: false,
  theme: 'colored',
};

const customToast = {
  success(msg: any, options = {}) {
    return toast.success(msg, {
      ...defaultOptions,
      ...options,
      className: 'toast-success-container toast-success-container-after',
      progressStyle: css({
        background: '#34A853',
      }),
    });
  },
  error(msg: any, options = {}) {
    return toast.error(msg, {
      ...defaultOptions,
      ...options,
      className: 'toast-error-container toast-error-container-after',
      progressStyle: css({
        background: '#EE0022',
      }),
    });
  },
  info(msg: any, options = {}) {
    return toast.info(msg, {
      ...defaultOptions,
      ...options,
      className: 'toast-info-container toast-info-container-after',
      progressStyle: css({
        background: '#07F',
      }),
    });
  },
  warning(msg: any, options = {}) {
    return toast.info(msg, {
      ...defaultOptions,
      ...options,
      className: 'toast-warning-container toast-warning-container-after',
      progressStyle: css({
        background: '#07F',
      }),
    });
  },
};

export default customToast;
