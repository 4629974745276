import css from './social.module.scss';
import Facebook from '@/assets/images/footer/facebook.svg';
import Twitter from '@/assets/images/footer/twitter.svg';
import Telegram from '@/assets/images/footer/telegram.svg';

const Social = () => {
  return (
    <div className={css['container']}>
      <div className={css['social-icon']}>
        <a href="https://www.facebook.com/medabotsofficial" rel="noreferrer" target="_blank">
          <img
            className={`filter-gray `}
            src={Facebook}
            style={{ height: '12px' }}
            alt="Facebook"
          />
        </a>
      </div>
      <div className={css['social-icon']}>
        <a href="https://twitter.com/MedabotsWorld" rel="noreferrer" target="_blank">
          <img
            className={`filter-gray ${css['socialIcon']}`}
            src={Twitter}
            style={{ height: '14px' }}
            alt="Twitter"
          />
        </a>
      </div>
      <div className={css['social-icon']}>
        <a href="https://t.me/MedabotsGame" rel="noreferrer" target="_blank">
          <img
            className={`filter-gray ${css['socialIcon']}`}
            src={Telegram}
            style={{ height: '14px' }}
            alt="Twitter"
          />
        </a>
      </div>
    </div>
  );
};

export default Social;
