/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  part: any;
  onClose: any;
  onClaim: Function;
  wallet: string;
};

const ClaimModal = (props: Props) => {
  const { isOpen, onClose, onClaim, part, wallet } = props;
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleClaim = async () => {
    try {
      toast.info('Claiming part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (wallet) await Web3.claimPart(part.id);

      toast.success('Part claimed!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      partEvent(GA_ACTION.CLAIM, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      partEvent(GA_ACTION.CLAIM, GA_LABEL.FAILURE);
    } finally {
      onClaim();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`CLAIM MEDAPART #${part.id}?`}</Modal.Header>
        <Modal.Body>{`Are you sure you want to claim the MEDAPART #${part.id}`}</Modal.Body>
        <Modal.Footer>
          <Button text="Claim" onClick={handleClaim} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimModal;
