import { authApi } from '@/config';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: authApi,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
