/**
@componentName Space
@description Distance between components
*/

/** Vendors */
import { bool, InferProps, number } from 'prop-types';

/** Types */
const propTypes = {
  inline: bool,
  space: number.isRequired,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const ComponentName = (props: Props) => {
  const { inline, space } = props;

  const style: any = { width: `${space}px`, height: `${space}px` };
  if (inline) style.display = 'inline-block';

  return <div style={style} />;
};

ComponentName.propTypes = propTypes;
ComponentName.defaultProps = defaultPropTypes;

export default ComponentName;
