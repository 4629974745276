import { Button, Modal } from '@/commons';
import { Web3 } from '@/services/web3';

import toast from '@/utils/customToast';

type Props = {
  isOpen: boolean;
  onClose: any;
  onSendToGame: Function;
  part: any;
  wallet: string;
};

const SendToGameModal = (props: Props) => {
  const { isOpen, onClose, onSendToGame, part, wallet } = props;
  const handleSendToGame = async () => {
    try {
      toast.info(`Sending part to the game`, {
        position: 'bottom-right',
        theme: 'colored',
      });

      await Web3.lockingNFT([part.id], wallet);

      toast.success(`Part has been sent successfully`, {
        position: 'bottom-right',
        theme: 'colored',
      });
    } catch (error) {
      toast.error(`An error ocurred`, {
        position: 'bottom-right',
        theme: 'colored',
      });
    } finally {
      onSendToGame();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`Are you sure you want to send medapart #${part.id} to the game?`}</Modal.Header>
        <Modal.Body>
          {
            <p>
              This medapart will not be available in the marketplace while it is in the game. Therefore
              you will not be able to sell it, transfer it or auction it until you recover it.
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button text="Send" onClick={handleSendToGame} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendToGameModal;
