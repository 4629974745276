/** Vendors */
import { useState } from 'react';

/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Input, Modal, Space } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';
import axiosInstance from '@/api/services.api';

type Props = {
  isOpen: boolean;
  onClose: any;
  onClaimCrystals: Function;
  wallet: string;
};

const ClaimCrystalModal = (props: Props) => {
  const { isOpen, onClose, onClaimCrystals, wallet } = props;
  const [crystalsNumber, setCrystalsNumber] = useState(3);
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleCrystallChange = (e: any) => {
    setCrystalsNumber(parseInt(e.target.value));
  };

  const handleClaim = async () => {
    try {
      toast.info('Claiming Crystals...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      const signature = await axiosInstance.post('/claimCrystals', {
        wallet: wallet,
      });

      if (wallet)
        await Web3.claimMon(
          signature.data.data.medamon,
          wallet,
          signature.data.data.signature,
          signature.data.data.idempotencyKey,
          signature.data.data.expirationBlock
        );

      toast.success('Mon claimed!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      onClose();

      partEvent(GA_ACTION.TRANSFER, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      onClose();

      partEvent(GA_ACTION.TRANSFER, GA_LABEL.FAILURE);
    } finally {
      onClaimCrystals();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`CLAIM YOUR MONS`}</Modal.Header>
        <Modal.Body>
          {`If you claim the MON you've won inside the game, you will add it to your wallet and you
          will be able use it in the marketplace`}
          <Space space={32} />
        </Modal.Body>
        <Modal.Footer>
          <Button text="Claim" onClick={handleClaim} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimCrystalModal;
