import { NavLink } from 'react-router-dom';
import { navLinks, Permissions } from '@/config';
import { useUserState } from '@/hooks';

interface Props {
  css: any;
  onClick?: () => void;
}

const Links = (props: Props) => {
  const { css, onClick = () => null } = props;
  const { userWalletLogged } = useUserState();

  return (
    <ul>
      {navLinks.map((route: any) => {
        const userWalletLoggedRequested = route.restrictions?.includes(Permissions.walletlogged);

        const drawLink =
          !userWalletLoggedRequested || (userWalletLoggedRequested && userWalletLogged);

        return route.isExternal ? (
          <li key={route.label} className={css.li}>
            <a
              key={`sitemap_${route.label}`}
              className={css.link}
              href={route.path}
              target="_blank"
              rel="noreferrer"
            >
              {route.label}
            </a>
          </li>
        ) : (
          drawLink && (
            <li key={route.label} className={css.li}>
              <NavLink
                key={`menu_${route.label}`}
                className={css.link}
                onClick={onClick}
                to={route.path}
              >
                {route.label}
              </NavLink>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default Links;
