import { Button, Modal } from '@/commons';
import { Web3 } from '@/services/web3';

import toast from '@/utils/customToast';

type Props = {
  isOpen: boolean;
  onClose: any;
  onRecoverFromGame: Function;
  part: any;
  wallet: string;
};

const RecoverFromGameModal = (props: Props) => {
  const { isOpen, onClose, onRecoverFromGame, part, wallet } = props;

  const handleRecoverFromGame = async () => {
    try {
      toast.info(`Recovering part from game`, {
        position: 'bottom-right',
        theme: 'colored',
      });

      await Web3.unlockingNFT([part.id], wallet);

      toast.success(`Part has been recovered successfully`, {
        position: 'bottom-right',
        theme: 'colored',
      });
    } catch (error) {
      toast.error(`An error ocurred`, {
        position: 'bottom-right',
        theme: 'colored',
      });
    } finally {
      onRecoverFromGame();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`Are you sure you want to recover medapart #${part.id} from game?`}</Modal.Header>
        <Modal.Body>
          {
            <div>
              <p>
                You are going to recover a game medapart. Keep in mind that if this medapart was
                part of a medabot, it will be disassembled and if that medabot belonged to a team,
                the medabot will also be removed from the teams to which it belonged.
              </p>
              <p style={{ marginTop: '25px' }}>
                Also, if that medapart is participating in the league you will be immediately
                disqualified from the current league and will not be able to face any opponent until
                the league is over.
              </p>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button text="Recover" onClick={handleRecoverFromGame} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecoverFromGameModal;
