import { useState } from 'react';
import Auction from './auction.component';
import Sell from './sell.component';
import styles from './sellForm.module.scss';

interface IProps {
  id: string;
  cb: any;
}

const SellForm = (props: IProps) => {
  const { id, cb } = props;
  const [selected, setSelected] = useState('sell');

  const handleOnClick = (type: string) => {
    setSelected(type);
  };

  return (
    <div className={styles.container}>
      <div className={styles['tabset']}>
        <input
          type="radio"
          name="tabset"
          id="tab1"
          aria-controls="sell"
          onClick={() => handleOnClick('sell')}
          checked={selected === 'sell'}
        />
        <label htmlFor="tab1">Fixed Price</label>

        <input
          type="radio"
          name="tabset"
          id="tab2"
          aria-controls="auction"
          onClick={() => handleOnClick('auction')}
          checked={selected === 'auction'}
        />
        <label htmlFor="tab2">Auction</label>

        <div className={styles['tab-panels']}>
          <section id="sell" className={styles['tab-panel']}>
            <Sell id={id} handleFixed={cb} />
          </section>
          <section id="auction" className={styles['tab-panel']}>
            <Auction id={id} handleAuction={cb} />
          </section>
        </div>
      </div>
    </div>
  );
};

export default SellForm;
