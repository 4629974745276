/** Vendors */
import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

/** Utils */
import '@/commons/utils/setDocumentHeight';
import store from './store/store';

/** Components */
import AppRouter from './appRouter';

/** Styles */
import 'react-toastify/dist/ReactToastify.css';
import './assets/sass/style.scss';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || '';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Suspense fallback={<h1>Medabots loading...</h1>}>
      <Provider store={store}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default App;
