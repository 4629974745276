/**
@componentName useInit
@description App Initialization
*/

/** Vendors */
import { useEffect } from 'react';

/** Utils */
import { setWallet, useDispatch } from '@/store';

const useInit = () => {
  const dispatch = useDispatch();
  const wallet = localStorage.getItem('wallet');

  useEffect(() => {
    wallet && dispatch(setWallet(wallet));
  }, [dispatch, wallet]);
};

export default useInit;
