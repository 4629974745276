import styles from './tag.module.scss';

const Tag = (props: any) => {
  const { img, txt } = props;
  return (
    <div className={styles.tag}>
      <div className={styles.icon}>
        <img src={img} alt="tag" />
      </div>
      <div className={styles.text}> {txt} </div>
    </div>
  );
};

export default Tag;
