/**
@componentName RarityFilter
@description
*/

/** Vendors */
import { func, InferProps } from 'prop-types';

/** Components */
import { Checkbox } from '@/commons';
import { RarityTag } from '@/components';
import FilterContainer from '../shared/container/filter.container';

/** Utils */
import { useDispatch, useSelector, setRarityFilter, toggleFilterCollapse } from '@/store';
import { elementToggle } from '@/commons/utils/array';

/** Constants */
import { RarityFilters } from '@/config';

/** Styles */
import css from './rarityFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'rarity';

const RarityFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const rarityName: string[] = useSelector(store => store.filters.rarityName);

  const handleOnFilter = (value: string) => {
    dispatch(setRarityFilter(elementToggle(rarityName, value)));
    onFilter && onFilter();
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Rarity</FilterContainer.Title>
        <FilterContainer.Body className={css.content}>
          {RarityFilters.map(filter => (
            <Checkbox
              checked={rarityName.includes(filter.key)}
              key={filter.key}
              label={<RarityTag rarity={filter.text} />}
              onToggle={handleOnFilter}
              value={filter.key}
            />
          ))}
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

RarityFilter.propTypes = propTypes;
RarityFilter.defaultProps = defaultPropTypes;

export default RarityFilter;
