/**
@componentName RobotFilter
@description Filter robot names
*/

/** Vendors */
import { useState } from 'react';
import { func, InferProps } from 'prop-types';

/** Components */
import FilterContainer from '../shared/container/filter.container';

/** Utils */
import { setRobotFilter, toggleFilterCollapse, useDispatch } from '@/store';

/** Constants */
import { RobotFilters } from '@/config';

/** Styles */
import css from './robotFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'robots';
const orderedRobots = [...RobotFilters].sort((a, b) => a.key.localeCompare(b.key));

const RobotFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();

  /** Layout */
  const [activeFilter, setActiveFilter] = useState('Select Medabot');
  const [isOpened, setIsOpened] = useState(false);

  /** Handlers */
  const handleIsOpen = () => setIsOpened(true);

  const handleOnFiltered = (filter: any) => {
    setActiveFilter(filter.text);
    setIsOpened(false);

    dispatch(setRobotFilter(filter.value ? [filter.value] : []));
    onFilter && onFilter(filter);
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Robots</FilterContainer.Title>
        <FilterContainer.Body>
          <div className={css.content}>
            <label className={css['dropdown']}>
              <div
                onClick={handleIsOpen}
                className={!isOpened ? css['dd-button'] : css['dd-button-opened']}
              >
                {activeFilter}
              </div>
              <input type="checkbox" className={css['dd-input']} id="test" />
              <ul className={css['dd-menu']}>
                {orderedRobots.map((filter: any) => (
                  <li key={filter.key} onClick={() => handleOnFiltered(filter)}>
                    {filter.text}
                  </li>
                ))}
              </ul>
            </label>
          </div>
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

RobotFilter.propTypes = propTypes;
RobotFilter.defaultProps = defaultPropTypes;

export default RobotFilter;
