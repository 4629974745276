/**
@componentName BodyPartFilter
@description Parts filter from type
*/

/** Vendors */
import { func, InferProps } from 'prop-types';

/** Components */
import { Toggle } from '@/commons';
import FilterContainer from '../shared/container/filter.container';

/** Utils */
import { setPartFilter, toggleFilterCollapse, useDispatch, useSelector } from '@/store';
import { elementToggle } from '@/commons/utils/array';

/** Constants */
import { BodyPartFilters } from '@/config';

/** Styles */
import css from './bodyPartFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'bodyPart';

const BodyPartFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const filterPartType: string[] = useSelector(store => store.filters.partType);

  const handleOnToggle = (value: any) => {
    dispatch(setPartFilter(elementToggle(filterPartType, value)));
    onFilter && onFilter();
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Body Part</FilterContainer.Title>
        <FilterContainer.Body className={css.content}>
          {BodyPartFilters.map((filter, idx) => (
            <Toggle
              data={filter.value}
              key={idx}
              img={filter.img}
              onToggle={handleOnToggle}
              selected={filterPartType.includes(filter.value)}
              text={filter.text}
            />
          ))}
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

BodyPartFilter.propTypes = propTypes;
BodyPartFilter.defaultProps = defaultPropTypes;

export default BodyPartFilter;
