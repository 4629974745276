/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';
import BidForm from './bidForm/bidForm.component';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  bid: any;
  bidStatus: any;
  isOpen: boolean;
  part: any;
  onBid: any;
  onClose: any;
  onOffer: Function;
  wallet: string;
};

const OfferModal = (props: Props) => {
  const { bid, bidStatus, isOpen, onBid, onClose, onOffer, part, wallet } = props;
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleMakeAnOffer = async () => {
    try {
      if (bid < bidStatus.highestBid) {
        toast.error('Your bid is lower than the highest bid', {
          position: 'bottom-right',
          theme: 'colored',
        });

        partEvent(GA_ACTION.BID, GA_LABEL.FAILURE);
        return;
      }

      toast.info('Making an offer...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (wallet) await Web3.makeAnOffer(part.id, bid, wallet);

      toast.success('Offer made!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      partEvent(GA_ACTION.BID, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      partEvent(GA_ACTION.BID, GA_LABEL.FAILURE);
    } finally {
      onOffer();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`MAKE AN OFFER FOR MEDAPART #${part.id}?`}</Modal.Header>
        <Modal.Body>
          <BidForm
            text={`You are going to make an offer for MEDAPART #${part.id}. The owner of the medapart will see it and may accept it. You’ll receive a notification.`}
            address={onBid}
            initialPrice={bidStatus && bidStatus.initialPrice}
            min={bidStatus && bidStatus.highestBid}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button text="Confirm" onClick={handleMakeAnOffer} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OfferModal;
