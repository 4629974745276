/** Vendors */
import { useTranslation } from 'react-i18next';

/** Utils */
import {
  useDispatch,
  filtersInitialstate,
  setAntiquityFilter,
  setEditionFilter,
  setFamilyFilter,
  setIdFilter,
  setPartFilter,
  setRarityFilter,
  setRobotFilter,
  setStatsFilters,
  setStatusFilter,
} from '@/store';
import { useDelay } from '@/hooks';

/** Components */
import { Chip } from '@/commons';

/** Constants */
import {
  filterServerNames,
  ANTIQUITY_PART_FILTER,
  BODY_PART_FILTER,
  EDITION_FILTER,
  FAMILY_FILTER,
  ID_FILTER,
  RARITY_FILTER,
  ROBOT_FILTER,
  STATS_FILTER,
  STATUS_FILTER,
} from '@/config';

/** Styles */
import css from './activeFilters.module.scss';

/** Types */
type Props = {
  filterStatus: any;
  numActiveFilters: number;
  onFilter: Function;
};

const antiquity = filterServerNames.ANTIQUITY_PART_FILTER;
const bodyPart = filterServerNames.BODY_PART_FILTER;
const edition = filterServerNames.EDITION_FILTER;
const family = filterServerNames.FAMILY_FILTER;
const id = filterServerNames.ID_FILTER;
const rarity = filterServerNames.RARITY_FILTER;
const robot = filterServerNames.ROBOT_FILTER;
const stats = filterServerNames.STATS_FILTER;
const status = filterServerNames.STATUS_FILTER;

const ActiveFilters = (props: Props) => {
  const { filterStatus, numActiveFilters, onFilter } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const run = useDelay();

  const handleOnFilter = () => {
    run(() => {
      onFilter && onFilter();
    });
  };

  const handleRemoveFilter = (filter: string) => {
    switch (filter) {
      case ANTIQUITY_PART_FILTER:
        dispatch(setAntiquityFilter(filtersInitialstate[antiquity]));
        break;
      case BODY_PART_FILTER:
        dispatch(setPartFilter(filtersInitialstate[bodyPart]));
        break;
      case EDITION_FILTER:
        dispatch(setEditionFilter(filtersInitialstate[edition]));
        break;
      case FAMILY_FILTER:
        dispatch(setFamilyFilter(filtersInitialstate[family]));
        break;
      case ID_FILTER:
        dispatch(setIdFilter(filtersInitialstate[id]));
        break;
      case RARITY_FILTER:
        dispatch(setRarityFilter(filtersInitialstate[rarity]));
        break;
      case ROBOT_FILTER:
        dispatch(setRobotFilter(filtersInitialstate[robot]));
        break;
      case STATS_FILTER:
        dispatch(setStatsFilters(filtersInitialstate[stats]));
        break;
      case STATUS_FILTER:
        dispatch(setStatusFilter(filtersInitialstate[status]));
        break;
    }

    handleOnFilter();
  };

  return numActiveFilters > 0 ? (
    <>
      <div className={css.container}>
        {filterStatus.antiquity && (
          <Chip
            onClick={() => handleRemoveFilter(ANTIQUITY_PART_FILTER)}
            text={t('filters.' + ANTIQUITY_PART_FILTER)}
          />
        )}
        {filterStatus.bodyPart && (
          <Chip
            onClick={() => handleRemoveFilter(BODY_PART_FILTER)}
            text={t('filters.' + BODY_PART_FILTER)}
          />
        )}
        {filterStatus.edition && (
          <Chip
            onClick={() => handleRemoveFilter(EDITION_FILTER)}
            text={t('filters.' + EDITION_FILTER)}
          />
        )}
        {filterStatus.family && (
          <Chip
            onClick={() => handleRemoveFilter(FAMILY_FILTER)}
            text={t('filters.' + FAMILY_FILTER)}
          />
        )}
        {filterStatus.id && (
          <Chip onClick={() => handleRemoveFilter(ID_FILTER)} text={t('filters.' + ID_FILTER)} />
        )}
        {filterStatus.rarity && (
          <Chip
            onClick={() => handleRemoveFilter(RARITY_FILTER)}
            text={t('filters.' + RARITY_FILTER)}
          />
        )}
        {filterStatus.robot && (
          <Chip
            onClick={() => handleRemoveFilter(ROBOT_FILTER)}
            text={t('filters.' + ROBOT_FILTER)}
          />
        )}
        {filterStatus.stats && (
          <Chip
            onClick={() => handleRemoveFilter(STATS_FILTER)}
            text={t('filters.' + STATS_FILTER)}
          />
        )}
        {filterStatus.status && (
          <Chip
            onClick={() => handleRemoveFilter(STATUS_FILTER)}
            text={t('filters.' + STATUS_FILTER)}
          />
        )}
      </div>
    </>
  ) : null;
};

export default ActiveFilters;
