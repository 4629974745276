import css from './priceTag.module.scss';
import MedamonIcon from '@/assets/images/shared/medamonIcon.png';

const PriceTag = (props: any) => {
  const { price, isOnSale } = props;

  return (
    <div className={css['price']}>
      {isOnSale ? (
        <>
          {price}
          <img src={MedamonIcon} alt="medamon" />
        </>
      ) : (
        'Not for sale'
      )}
    </div>
  );
};

export default PriceTag;
