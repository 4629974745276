import css from './toggle.module.scss';

type Props = {
  data: string | Array<string>;
  img?: string;
  onToggle?: Function;
  selected?: boolean;
  text?: string;
};

const Toggle = (props: Props) => {
  const { data, img, onToggle, selected, text } = props;

  const handleClick = () => {
    onToggle && onToggle(data);
  };

  return (
    <div onClick={handleClick} className={`${css.toggle} ${selected ? css.active : ''} c-toggle`}>
      {img && <img className={css.icon} src={img} alt={''} />}
      {text && <span className={css.text}>{text}</span>}
    </div>
  );
};

export default Toggle;
