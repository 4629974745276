import { gql } from '@apollo/client';
import { client } from '../api/graphql';

const winnerAuctions = `query winnerAuctions($address: String, $currentTime: BigInt!) 
  {
    listedAuctions(where:{highestBidder:$address, endTime_lt:$currentTime}) {
      id
      token
      lister {
        id
      }
      highestBidder {
        id
      }
      initialPrice
      highestBid
      endTime
    }
}`;

const bids = `query bids ($tokenPart: String ) {
    bids(where: {token: $tokenPart}, orderBy: createdAt, orderDirection: desc) {
      id
      bidder {
        id
      }
      amount
      createdAt
    }
  }`;

export const getWinnerAuctions = async (address: string, currentTime: string) => {
  const response = await client.query({
    query: gql(winnerAuctions),
    variables: {
      address: address,
      currentTime: currentTime,
    },
    fetchPolicy: 'no-cache',
  });

  return response.data.listedAuctions;
};

export const getBids = async (tokenPart: string) => {
  const result = await client.query({
    query: gql(bids),
    variables: {
      tokenPart: tokenPart,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.bids;
};
