/**
 * @componentName Icon
 * @description Icons with variants
 */

/** Vendors */
import { any, bool, func, InferProps, number, oneOf, string } from 'prop-types';

/** Styles */
import css from './icon.module.scss';

const propTypes = {
  /** Color */
  color: string,
  /** Disable */
  disabled: bool,
  /** Flip X */
  flipX: bool,
  /** Flip Y */
  flipY: bool,
  /** Icon */
  icon: string.isRequired,
  /** Callback OnClick */
  onClick: func,
  /** Opacity */
  opacity: oneOf([100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]).isRequired,
  /** Size */
  size: number,
  /** Style */
  style: any,
};

const defaultPropTypes = {
  size: 24,
  opacity: 100,
};

type Props = InferProps<typeof propTypes>;

const Icon = (props: Props) => {
  const { color, disabled, flipX, flipY, icon, onClick, opacity, size, style, ...rest } = props;

  const handleOnClick = (e: any) => {
    onClick && onClick(e);
  };

  return (
    <>
      <div
        {...rest}
        className={`c-icon ${css['container']} ${icon} ${color ? css[color] : ''} ${
          disabled ? css['disabled'] : ''
        } ${flipX ? css['flipX'] : ''} ${flipY ? css['flipY'] : ''}`}
        onClick={handleOnClick}
        style={{
          width: size + 'px',
          height: size + 'px',
          backgroundImage: 'url(' + require('@/assets/images/icons/' + icon + '.svg') + ')',
          opacity: opacity / 100,
          ...style,
        }}
      />
    </>
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultPropTypes;

export default Icon;
