/** Vendors */
import { useState } from 'react';

/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Input, Modal, Space } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  partId: string;
  onClose: any;
  onTransfer: Function;
  wallet: string;
};

const TransferModal = (props: Props) => {
  const { isOpen, onClose, onTransfer, partId, wallet } = props;
  const [toAddress, setToAddress] = useState('');
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleAddresChange = (e: any) => {
    setToAddress(e.target.value);
  };

  const handleTransfer = async () => {
    try {
      toast.info('Transferring part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (wallet) await Web3.transferPart(toAddress, partId, wallet);

      toast.success('Part transferred!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      partEvent(GA_ACTION.TRANSFER, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      partEvent(GA_ACTION.TRANSFER, GA_LABEL.FAILURE);
    } finally {
      onTransfer();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`TRANSFER MEDAPART #${partId}?`}</Modal.Header>
        <Modal.Body>
          {`You are about to transfer MEDAPART #${partId} to other person. Introduce their Metamask Wallet Address to continue:`}
          <Space space={32} />
          <Input onChange={handleAddresChange} placeHolder="Insert Address" />
        </Modal.Body>
        <Modal.Footer>
          <Button text="Transfer" onClick={handleTransfer} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TransferModal;
