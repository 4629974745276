import styles from './sell.module.scss';

interface IProps {
  id: string;
  handleFixed?: any;
  handleAuction?: any;
}

const Sell = (props: IProps) => {
  const { id, handleFixed } = props;

  const type = 'fixedPrice';
  const handleOnChange = (e: any) => {
    const value = e.target.value;
    handleFixed({ type, id, ...{ price: value } });
  };

  return (
    <div className={styles.container}>
      <p>
        Your asset will be listed on the marketplace at a fixed price. In order to get it back,
        you’ll have to cancel the sale
      </p>
      <div className={styles['input-container']}>
        <div className={styles.label}>SELL AT:</div>
        <input
          className={styles.input}
          type="number"
          placeholder="Introduce your price (MON)"
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default Sell;
