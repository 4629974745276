import { Button, Input } from '@/commons';
import { setUser, useDispatch } from '@/store';
import css from './profileWalletAssociation.module.scss';
import toast from '@/utils/customToast';
import { setWallet, unSetWallet } from '@/services/wallet';
import { Web3 } from '@/services/web3';

type Props = {
  user: any;
};

const ProfileWalletAssociation = (props: Props) => {
  const { user } = props;
  const dispatch = useDispatch();

  const handleDissasociate = async () => {
    try {
      const signedAddress = await Web3.signAddress(user.wallet);
      await unSetWallet(signedAddress);
      dispatch(setUser({ ...user, associatedWallet: null }));
      toast.success('Wallet dissassociated successfully', { position: 'bottom-right' });
    } catch {
      toast.error('Something went wrong', { position: 'bottom-right' });
    }
  };

  const handleAssociate = async () => {
    try {
      const signedAddress = await Web3.signAddress(user.wallet);
      await setWallet(signedAddress);
      dispatch(setUser({ ...user, associatedWallet: user.wallet }));
      toast.success('Wallet associated successfully', { position: 'bottom-right' });
    } catch (error) {
      toast.error('Something went wrong', { position: 'bottom-right' });
    }
  };

  return (
    <>
      {user && (
        <div className={`${css['container']} `}>
          <div>
            <label className={css['label']}>Associated address</label>
            <Input
              className={css.input}
              disabled
              value={user.associatedWallet ? user.associatedWallet : 'Not setted address'}
            />
            <div className={css['button-container']}>
              <Button disabled={!!user.associatedWallet} onClick={handleAssociate}>
                Set wallet
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                disabled={!user.associatedWallet}
                onClick={handleDissasociate}
              >
                Unset wallet
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileWalletAssociation;
