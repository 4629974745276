import services from '../api/services.api';

export const getProfile = () =>
  services.post('getProfile').then((response: { data: any }) => {
    return response.data.data;
  });

export const hasProfile = async (wallet: string) => {
  if (!wallet) return false;
  const result = await services.post('hasProfile', { wallet });
  return result.data?.message;
};
