/** Vendors */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/** Utils */
import {
  RootState,
  useDispatch,
  useSelector,
  getFilteredParts,
  resetFilters,
  setFilters,
} from '@/store';
import { Web3 } from '@/services/web3';

/** Components */
import { Space } from '@/commons';
import Filters from '@/containers/filters/filters.component';
import BackButton from '@/components/backButton/backButton.component';
import About from './components/about/about.component';
import PiecesList from '@/containers/piecesList/piecesList.component';

/** Images && Icons */

/** Styles */
import css from './usersInventory.module.scss';

const UsersInventory = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [, setTotal] = useState(0);
  const [user, setUser] = useState<any>(null);

  const wallet = useSelector((store: RootState) => store.user.wallet)?.toLowerCase();

  useEffect(() => {
    const newUser = location.pathname.split('/')[2];
    setUser(newUser);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(setFilters({ numItems: 1000, owner: user }));
      dispatch(getFilteredParts());
    }
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch, user]);

  useEffect(() => {
    const getTotal = async () => {
      const newTotal = await Web3.getNFTBalance(wallet);
      setTotal(newTotal);
    };

    getTotal();
  }, [user, wallet]);

  return (
    <main className={css['inventory']}>
      <div className={css['filters']}>
        <Filters />
      </div>
      <div className={css['main']}>
        <BackButton />
        <About user={user} />
        <Space space={40} />
        <PiecesList owner={user} />
      </div>
    </main>
  );
};

export default UsersInventory;
