import { useSelector } from '@/store';

// import ShareInGame from '@/components/shareInGame/shareInGame.component';

import ProfileAccountStatus from '../../components/profileAccountStatus/profileAccountStatus.component';
import ProfileDataUser from '../../components/profileUserData/profileDataUser.component';
import ProfileWalletAssociation from '../../components/profileWalletAssociation/profileWalletAssociation.component';
import css from './profile.module.scss';

const Profile = () => {
  const user = useSelector((state: any) => state.user);

  return (
    <main className={css.profile}>
      <div className={css.main}>
        {/* <div className={css.title}>connect with game</div> */}
        <div className={`grid ${css.body}`}>
          <div className={`col-desk-10 col-tab-6 col-mob-12 ${css.column}`}>
            <div className={`${css['top-left']}`}>
              <ProfileDataUser user={user} />
            </div>
          </div>
          <div className={`col-desk-10 col-tab-6 col-mob-12 ${css.column}`}>
            <div className={`${css.top} ${!user?.email && css['disabled']}`}>
              <ProfileWalletAssociation user={user} />
            </div>
            <div
              className={`${css.bottom} ${
                (!user?.email || !user.associatedWallet) && css['disabled']
              }`}
            >
              <ProfileAccountStatus user={user} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
