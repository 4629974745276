import services from '../api/services.api';

interface ISetWallet {
    message: string;
    signedMessage: string;
}

export const setWallet = (body: ISetWallet) =>
services.post('setWallet', body).then((response: { data: any }) => response.data);

export const unSetWallet = (body: ISetWallet) =>
services.post('unsetWallet', body).then((response: { data: any }) => response.data);