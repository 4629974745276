/** Vendors */
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Utils */
import { getPartById } from '../../services/parts';
import { timeSince } from '../../utils/timeSince';
import { getMovements } from '../../services/movements';
import { fromBlockchain } from '../../utils/formatMon';
import { getCardsImage, getRobotImage } from '../../utils/getImages.util';
import { getShortAddress } from '@/utils/formatAddress';
import { Web3 } from '@/services/web3';
import { getBids } from '@/services/auction';
import { getAntiquity } from '@/utils/dates';
import { getBalance, useDispatch, useSelector } from '@/store';
import toast from '@/utils/customToast';

/** Components */
import { Button } from '@/commons';
import Table from '../../components/table/table.component';
import Stats from '@/components/stats/stats.component';
import TransferModal from './components/transferModal.component';
import SellModal from './components/sellModal.component';
import UnlistModal from './components/unlistModal.component';
import BuyModal from './components/buyModal.component';
import ClaimModal from './components/claimModal.component';
import OfferModal from './components/offerModal.component';
import BackButton from '@/components/backButton/backButton.component';
import BodyImage from './components/bodyImage/bodyImage.component';
import Cards from './components/cards/cards.component';

/** Constants */
import { BodyPartFilters, FamilyFilters } from '@/config';

/** Styles */
import css from './partDetail.module.scss';
import MedamonIcon from '@/assets/images/shared/medamonIcon.png';
import ShareInGame from '@/components/shareInGame/shareInGame.component';
import RepairModal from './components/unlistModalSecurity.component';

const fetchFamilyIcon = (familyName: string) =>
  FamilyFilters.find((e: any) => e.key === familyName);

const fetchBodyPartIcon = (partType: string) =>
  BodyPartFilters.find((e: any) => e.key === partType);

const renderIcons = (icon: any, text: string) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img style={{ height: '60px', width: 'auto' }} src={icon} alt={text} />
      <span
        style={{
          paddingLeft: '8px',
          fontFamily: 'Odibee',
          fontSize: '2rem',
          lineHeight: '2.2rem',
        }}
      >
        {text}
      </span>
    </div>
  );
};

const PartDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [part, setPart]: any = useState();
  const [visibleModal, setVisibleModal] = useState('');
  const robotImage = getRobotImage(part?.robotName);

  const [movements, setMovements] = useState([]);
  const [bid, setBid] = useState(0);
  const [bids, setBids] = useState([]);
  const [bidStatus, setBidStatus] = useState({
    lister: '',
    initialPrice: 0,
    endTime: 0,
    highestBidder: '',
    highestBid: 0,
  });
  const [alertEndAuction, setAlertEndAuction] = useState(false);

  const wallet = useSelector(store => store.user.wallet)?.toLowerCase();
  const isYours = wallet && part?.owner?.id === wallet;
  const isOnSale = part?.isOnSale === true && part?.isOnAuction === false;
  const isOnAuction = part?.isOnAuction === true && part?.isOnSale === true;
  const isInGame = part?.isInGame === true;
  const isAuctionOpen = Date.now() / 1000 < bidStatus.endTime;

  const areYouWinner = () => {
    if (isAuctionOpen) return false;
    return bids.length === 0
      ? isYours
      : bidStatus?.highestBidder?.toLowerCase() === wallet?.toLowerCase();
  };

  const handleRefresh = () => {
    setTimeout(async () => {
      const newPart = await getPartById(location.pathname.split('/')[2]);
      setPart(newPart?.tokenParts[0] || null);
      dispatch(getBalance());
      setVisibleModal('');
    }, 2000);
  };

  const handleOnBid = (e: string) => setBid(parseFloat(e));

  const cards = part && getCardsImage(part.robotName, part.partType);

  const renderMovements = (movs: any) => {
    return movs.length > 0
      ? movs.map((movement: any) => {
          const { type, price, user, to, createdAt } = movement;
          return [
            { key: 'events', value: t(type) },
            {
              key: 'price',
              value: price ? fromBlockchain(parseInt(price)) : '-',
              icon: MedamonIcon,
            },
            {
              key: 'from',
              value:
                user && user.id ? (
                  <Link to={`/users/${user.id}`} title={user?.id}>
                    {' '}
                    {getShortAddress(user?.id)}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              key: 'to',
              value:
                to && to.id ? (
                  <Link to={`/users/${to?.id}`} title={to?.id}>
                    {' '}
                    {getShortAddress(to?.id)}
                  </Link>
                ) : (
                  '-'
                ),
            },
            { key: 'date', value: timeSince(parseInt(createdAt)) },
          ];
        })
      : [];
  };

  const renderBids = () => {
    return bids.length > 0
      ? bids.map(({ amount, bidder, createdAt }: any) => {
          return [
            { key: 'price', value: fromBlockchain(parseInt(amount)), icon: MedamonIcon },
            {
              key: 'from',
              value:
                bidder && bidder.id ? (
                  <Link to={`/users/${bidder.id}`}> {getShortAddress(bidder?.id)}</Link>
                ) : (
                  '-'
                ),
            },
            { key: 'date', value: timeSince(parseInt(createdAt)) },
          ];
        })
      : [];
  };

  const renderButtons = () => {
    if (!isOnSale && !isOnAuction && isYours) {
      return (
        <>
          {!isInGame && <Button text="Transfer" onClick={() => setVisibleModal('transfer')} />}
          {!isInGame && <Button text="Sell" onClick={() => setVisibleModal('sell')} />}
          <ShareInGame part={part} wallet={wallet} isInGame={!!part.isInGame} />
        </>
      );
    }

    if (part.status === '9') {
      return isYours ? <Button text="Repair" onClick={() => setVisibleModal('repair')} /> : null;
    }

    if (isOnSale) {
      return isYours ? (
        <Button text="Unlist" onClick={() => setVisibleModal('unlist')} />
      ) : (
        <Button text="Buy now" onClick={() => setVisibleModal('buy')} />
      );
    }

    if (isOnAuction) {
      if (isYours && bids.length === 0) {
        return <Button text="Unlist" onClick={() => setVisibleModal('unlist')} />;
      }

      return areYouWinner() ? (
        <Button text="Claim" onClick={() => setVisibleModal('claim')} />
      ) : (
        <Button text="Make Offer" onClick={() => setVisibleModal('offer')} />
      );
    }
  };

  /** Init */
  useEffect(() => {
    const fecthMovements = async () => {
      try {
        setMovements((await getMovements(part.id)).movements);
      } catch {}
    };
    part && fecthMovements();
  }, [part]);

  useEffect(() => {
    if (wallet) dispatch(getBalance());
  }, [dispatch, wallet]);

  useEffect(() => {
    if (alertEndAuction || bidStatus.endTime === 0) return;
    const secondsLeft = bidStatus.endTime - Math.floor(Date.now() / 1000);
    if (!secondsLeft || secondsLeft < 0) return;
    const days = Math.floor(secondsLeft / 86400);
    const hours = Math.floor((secondsLeft % 86400) / 3600);
    const minutes = Math.floor((secondsLeft % 3600) / 60);

    const timeLeft = `${days} days, ${hours} hours and ${minutes} minutes left`;

    toast.warning(`${timeLeft}`, {
      position: 'bottom-right',
      theme: 'colored',
    });

    setAlertEndAuction(true);
    // eslint-disable-next-line
  }, [bidStatus]);

  useEffect(() => {
    const fetchPart = async () => {
      try {
        const newPart = (await getPartById(location.pathname.split('/')[2]))?.tokenParts[0];
        if (newPart.status === '9' && isYours) setVisibleModal('repair')
        setPart(newPart || null);
      } catch {}
    };
    fetchPart();
  }, [location.pathname, isYours]);

  useEffect(() => {
    const fetchBids = async () => {
      try {
        setBids(await getBids(part.id));
      } catch {}
    };
    part && fetchBids();
  }, [part]);

  useEffect(() => {
    const fetchBidStatus = async () => {
      try {
        const newBidStatus = await Web3.getBidStatus(part.id);
        setBidStatus({
          lister: newBidStatus.lister,
          initialPrice:
            newBidStatus &&
            newBidStatus.initialPrice &&
            parseFloat(fromBlockchain(newBidStatus.initialPrice)!),
          endTime: parseInt(newBidStatus.endTime),
          highestBidder: newBidStatus.highestBidder,
          highestBid:
            newBidStatus &&
            newBidStatus.initialPrice &&
            parseFloat(fromBlockchain(newBidStatus.highestBid)!),
        });
      } catch {}
    };
    part && wallet && fetchBidStatus();
  }, [part, wallet]);

  return !part ? null : (
    <>
      <main className={css['container']}>
        {robotImage && (
          <div
            className={`${css['bg']} ${css[part.robotName]}`}
            style={{ backgroundImage: 'url(' + robotImage + ')' }}
          />
        )}

        {part?.owner.id !== '' && (
          <>
            <BackButton />

            <div className={css['header']}>
              <div className={css['info']}>
                <div className={css['id']}>
                  {t('g.piece')}
                  <span>{`#${part.id}`}</span>
                </div>
                <div className={css['antiquity']}>Antiquity: {getAntiquity(part.createdAt)}</div>
              </div>
              <div className={css['buttons-container']}>{renderButtons()}</div>
            </div>

            <div className={css['details']}>
              <BodyImage part={part} />

              <div className={css['about']}>
                <div className={css['type']}>
                  {part.partType} / {part.robotName}
                </div>

                {wallet !== part.owner.id && (
                  <div className={css['owner']}>
                    <div className={css['name']}>Owner:</div>
                    <Link className={css['exName']} to={`/users/${part.owner.id}`}>
                      ExName
                    </Link>
                    <span className={css['exName-value']}>({part.owner.id})</span>
                  </div>
                )}

                <div className={css['mobile-buttons']}>{renderButtons()}</div>

                <div className={css['characteristics']}>
                  <div className={css['family']}>
                    {renderIcons(
                      fetchFamilyIcon(part.familyName)?.img,
                      fetchFamilyIcon(part.familyName)!.value
                    )}

                    {renderIcons(
                      fetchBodyPartIcon(part.partType)?.img,
                      fetchBodyPartIcon(part.partType)!.text
                    )}
                  </div>

                  <div className={css['stats']}>
                    <Stats
                      health={part.health}
                      speed={part.speed}
                      attack={part.attack}
                      defense={part.defense}
                    />
                  </div>
                </div>
              </div>

              <Cards cards={cards} />
            </div>
          </>
        )}

        <div className={css['history']}>
          {part && part.isOnSale && part.isOnAuction && bids.length > 0 && (
            <>
              <div className={css['title']}>Offers</div>
              <Table data={renderBids()} />
            </>
          )}
          <>
            <div className={css['title']}>Item History</div>
            {movements.length > 0 && <Table data={renderMovements(movements)} />}
          </>
        </div>
      </main>

      <TransferModal
        isOpen={visibleModal === 'transfer'}
        onClose={() => setVisibleModal('')}
        onTransfer={handleRefresh}
        partId={part.id}
        wallet={wallet}
      />

      <SellModal
        isOpen={visibleModal === 'sell'}
        onClose={() => setVisibleModal('')}
        onSell={handleRefresh}
        partId={part.id}
        wallet={wallet}
      />

      <UnlistModal
        isOpen={visibleModal === 'unlist'}
        onClose={() => setVisibleModal('')}
        onUnlist={handleRefresh}
        part={part}
        wallet={wallet}
      />

      <RepairModal
        isOpen={visibleModal === 'repair'}
        onClose={() => setVisibleModal('')}
        onUnlist={handleRefresh}
        part={part}
        wallet={wallet}
      />

      <BuyModal
        isOpen={visibleModal === 'buy'}
        onClose={() => setVisibleModal('')}
        onBuy={handleRefresh}
        part={part}
        wallet={wallet}
      />

      <ClaimModal
        isOpen={visibleModal === 'claim'}
        onClose={() => setVisibleModal('')}
        onClaim={handleRefresh}
        part={part}
        wallet={wallet}
      />

      <OfferModal
        bid={bid}
        bidStatus={bidStatus}
        isOpen={visibleModal === 'offer'}
        onBid={handleOnBid}
        onClose={() => setVisibleModal('')}
        onOffer={handleRefresh}
        part={part}
        wallet={wallet}
      />
    </>
  );
};

export default PartDetail;
