/**
@componentName IdFilter
@description Parts filter from id
 */

/** Vendors */
import { useEffect, useRef } from 'react';
import { func, InferProps } from 'prop-types';

/** Components */
import { Input } from '@/commons';
import FilterContainer from '../shared/container/filter.container';

/** Utils */
import { setIdFilter, toggleFilterCollapse, useDispatch, useSelector } from '@/store';
import { useDelay } from '@/hooks';

/** Styles */
import css from './idFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'id';

const IdFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const idFilter: string = useSelector(store => store.filters.id);
  const run = useDelay();
  const ref: any = useRef();

  const handleOnChange = (e: any) => {
    run(() => {
      const newValue = e.target.value;

      dispatch(setIdFilter(newValue));
      onFilter && onFilter();
    });
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  useEffect(() => {
    ref.current.value = idFilter;
  }, [idFilter]);

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Medapart ID</FilterContainer.Title>
        <FilterContainer.Body>
          <div className={css.content}>
            <Input
              onChange={handleOnChange}
              placeholder="Insert Medapart Id"
              cref={ref}
              styleInput={{ height: '4rem' }}
              type="number"
            />
          </div>
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

IdFilter.propTypes = propTypes;
IdFilter.defaultProps = defaultPropTypes;

export default IdFilter;
