/**
@componentName useDelay
@description Hook to delay a function call avoiding recalls
*/

/** Vendors */
import { useEffect, useRef } from 'react';

const useDelay = (delay: number = 1000) => {
  const mutex: any = useRef(false);
  const timeout: any = useRef(delay);

  const run = (func: Function) => {
    timeout.current = clearTimeout(timeout.current);

    if (delay === 0) {
      mutex.current === false && func();
      mutex.current = true;

      setTimeout(() => {
        mutex.current = false;
      }, 1000);
    } else {
      timeout.current = setTimeout(() => {
        func();
      }, delay);
    }
  };

  useEffect(() => {
    return clearTimeout(timeout.current);
  }, []);

  return run;
};

export default useDelay;
