import { ethers } from 'ethers';

export const chainId = ethers.utils.hexValue(56);

export const medamon = process.env.REACT_APP_MEDAMON;

export const medaPart = process.env.REACT_APP_MEDAPART;
export const factoryPart = process.env.REACT_APP_FACTORYPART;

export const feeBeneficiary = process.env.REACT_APP_FEEBENEFICIARY;

export const marketplaceFixedPrice = process.env.REACT_APP_FIXEDPRICE;
export const marketplaceAuction = process.env.REACT_APP_AUCTION;

export const oldMarketplaceFixedPrice = process.env.REACT_APP_OLDFIXEDPRICE;
export const oldMarketplaceAuction = process.env.REACT_APP_OLDAUCTION;

export const crystalPool = process.env.REACT_APP_CRYSTALPOOL;

export const lockNFT = process.env.REACT_APP_LOCKNFT;