import ReactGA from 'react-ga';

const useAnalyticsEventTracker = (category = 'Marketplace category') => {
  const eventTracker = (
    action = 'test action',
    label = 'test label',
    value = 1,
    nonInteraction = false
  ) => {
    ReactGA.event({ category, action, label, value, nonInteraction });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
