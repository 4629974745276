import styles from './bidForm.module.scss';

const BidForm = ({
  text,
  address,
  initialPrice,
  min = 0,
}: {
  text: string;
  address: any;
  initialPrice: number;
  min?: number;
}) => {
  const handleOnChange = (e: any) => {
    address(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>

      <div className={styles['input-container']}>
        <input
          className={styles.input}
          type="text"
          placeholder="Insert your bid"
          onChange={handleOnChange}
        />
        <span className={styles.notice}>
          The recommended bid is{' '}
          {min !== 0
            ? parseFloat((min * 1.05).toFixed(2))
            : !initialPrice || initialPrice < 1
            ? 1
            : initialPrice * 1.85}{' '}
          MON
        </span>
      </div>
    </div>
  );
};

export default BidForm;
