/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  part: any;
  onClose: any;
  onUnlist: Function;
  wallet: string;
};

const UnlistModal = (props: Props) => {
  const { isOpen, onClose, onUnlist, part, wallet } = props;
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleUnlist = async () => {
    try {
      toast.info('Unlisting part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (part.isOnAuction === false) {
        if (wallet) await Web3.unlistPart(part.id);
        partEvent(GA_ACTION.UNLIST_FIXED, GA_LABEL.SUCCESS);
      } else {
        if (wallet) await Web3.unlistPartAuction(part.id);
        partEvent(GA_ACTION.UNLIST_AUCTION, GA_LABEL.SUCCESS);
      }

      toast.success('Part unlisted!', {
        position: 'bottom-right',
        theme: 'colored',
      });
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );
    } finally {
      onUnlist();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`Unlist medapart #${part.id}?`}</Modal.Header>
        <Modal.Body>
          {`Are you sure you want to withdraw MEDAPART #${part.id} from sale? You will be redirected to Metamask Wallet to complete the operation.`}
        </Modal.Body>
        <Modal.Footer>
          <Button text="Unlist" onClick={handleUnlist} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnlistModal;
