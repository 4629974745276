/** Vendors */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

/** Utils */
import {
  RootState,
  getFilteredParts,
  setPaginationFilter,
  useDispatch,
  useSelector,
} from '@/store';
import { Web3 } from '@/services/web3';

/** Components */
import ViewSelector from '@/components/viewSelector/viewSelector.component';
import DropdownButton from '@/components/dropDownButton/dropDownButton.component';
import List from './components/list/list.component';
import { InfinityScroll } from '@/commons';

/** Styles */
import css from './piecesList.module.scss';

type Props = {
  owner?: string;
};

const PiecesList = (props: Props) => {
  const { owner } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const filterPage = useSelector((store: RootState) => store.filters.page);
  const pieces = useSelector((store: RootState) => store.parts);

  /** Layout */
  const [showCards, setShowCards] = useState(true);
  const [total, setTotal] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [parts, setParts]: any = useState([]);
  const page = useRef(0);

  /** Handlers */
  const handleFilters = () => {
    handleResetPagination();
    dispatch(getFilteredParts());
  };

  const handleGetItems = useCallback(() => {
    page.current > 0 && dispatch(setPaginationFilter(page.current));
    dispatch(getFilteredParts());
    page.current++;
  }, [dispatch]);

  const handleResetPagination = () => {
    page.current = 0;
  };

  const handleShowList = (value: string) => {
    const result = value === 'list';
    setShowCards(result);
  };

  /** Init */
  useEffect(() => {
    const getTotal = async () => {
      const piecesTotal = await Web3.getNFTBalance(owner);
      setTotal(piecesTotal);
    };

    getTotal();
  }, [owner]);

  useEffect(() => {
    setHasMoreItems(pieces.length > 0);
    setParts((parts: any) => (filterPage === 0 ? pieces : [...parts, ...pieces]));
    // eslint-disable-next-line
  }, [setParts, pieces]);

  return (
    <>
      <div className={css['container']}>
        {(location.hash === '#pieces' || location.hash === '') && (
          <>
            <div className={css['header']}>
              <div className={css['counter']}>
                {total}
                <span>MEDAPARTS</span>
              </div>

              <div className={css['selectors']}>
                <DropdownButton onFilter={handleFilters} />
                <ViewSelector onSelectView={handleShowList} />
              </div>
            </div>

            <InfinityScroll getItems={handleGetItems} hasMoreItems={hasMoreItems}>
              <List pieces={parts} showCards={showCards} />
            </InfinityScroll>
          </>
        )}
      </div>
    </>
  );
};

export default PiecesList;
