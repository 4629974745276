/** Vendors */
import { useCallback, useEffect, useRef, useState } from 'react';

/** Utils */
import {
  getFilteredParts,
  hideBodyScroll,
  resetFilters,
  setPaginationFilter,
  showBodyScroll,
  useDispatch,
} from '@/store';
import { useClickOutside } from '@/hooks';
import { isDesktop } from '@/commons/utils/device';
import { useFiltersStatus } from './filters.hooks';

/** Components */
import { Scroll } from '@/commons';
import ActiveFilters from './components/activeFilters/activeFilters.component';
import AntiquityFilter from './components/antiquityFilter/antiquityFilter.component';
import BodyPartFilter from './components/bodyPartFilter/bodyPartFilter.component';
import IdFilter from './components/idFilter/idFilter.component';
import RarityFilter from './components/rarityFilter/rarityFilter.component';
import RobotFilter from './components/robotFilter/robotFilter.component';
import FamilyFilter from './components/familyFilter/familyFilter.component';
import StatsFilter from './components/statsFilter/statsFilter.component';
import StatusFilter from './components/statusFilter/statusFilter.component';
import EditionFilter from './components/editionFilter/editionFilter.component';

/** Constants */
import {
  enabledFilters,
  ANTIQUITY_PART_FILTER,
  BODY_PART_FILTER,
  EDITION_FILTER,
  FAMILY_FILTER,
  ID_FILTER,
  RARITY_FILTER,
  ROBOT_FILTER,
  STATS_FILTER,
  STATUS_FILTER,
} from '@/config';

/** Styles */
import css from './filters.module.scss';
import IconClose from '@/assets/images/shared/close.svg';
import IconFilters from '@/assets/images/shared/filter.svg';

type Props = {
  onFilter?: Function;
};

const Filters = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const ref: any = useRef();
  useClickOutside(ref, handleCloseFilters);
  const [visible, setVisible] = useState(false);
  const [desktop, setDesktop] = useState(isDesktop());
  const { filterStatus, numActiveFilters } = useFiltersStatus();

  const handleOpenFilters = () => {
    dispatch(hideBodyScroll());
    setVisible(true);
  };

  function handleCloseFilters() {
    if (visible) {
      dispatch(showBodyScroll());
      setVisible(false);
    }
  }

  const handleFilter = () => {
    dispatch(setPaginationFilter(0));
    dispatch(getFilteredParts());
    onFilter && onFilter();
  };

  const checkDevice = () => {
    setDesktop(isDesktop());
  };

  const handleResetFilters = useCallback(() => {
    dispatch(resetFilters());
    dispatch(getFilteredParts());
    onFilter && onFilter();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(showBodyScroll());
    };
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  return (
    <>
      <div className={css['container']} ref={ref} hidden={!desktop && !visible}>
        <div className={css.actions}>
          <div className={css.close} onClick={handleCloseFilters}>
            <img src={IconClose} alt="close" />
          </div>
          <div className={css.reset} onClick={handleResetFilters}>
            Reset all filters
          </div>
        </div>

        <Scroll isDisabled={desktop}>
          <div className={css.content}>
            <ActiveFilters
              filterStatus={filterStatus}
              numActiveFilters={numActiveFilters}
              onFilter={handleFilter}
            />
            {enabledFilters.includes(ROBOT_FILTER) && <RobotFilter onFilter={handleFilter} />}
            {enabledFilters.includes(BODY_PART_FILTER) && (
              <BodyPartFilter onFilter={handleFilter} />
            )}
            {enabledFilters.includes(STATUS_FILTER) && <StatusFilter onFilter={handleFilter} />}
            {enabledFilters.includes(RARITY_FILTER) && <RarityFilter onFilter={handleFilter} />}
            {enabledFilters.includes(FAMILY_FILTER) && <FamilyFilter onFilter={handleFilter} />}
            {enabledFilters.includes(STATS_FILTER) && <StatsFilter onFilter={handleFilter} />}
            {enabledFilters.includes(EDITION_FILTER) && <EditionFilter onFilter={handleFilter} />}
            {enabledFilters.includes(ID_FILTER) && <IdFilter onFilter={handleFilter} />}
            {enabledFilters.includes(ANTIQUITY_PART_FILTER) && (
              <AntiquityFilter onFilter={handleFilter} />
            )}
          </div>
        </Scroll>
      </div>

      <div className={css['icon']} onClick={handleOpenFilters}>
        <img src={IconFilters} alt="filters" />
        {numActiveFilters > 0 && <div className={css.counter}>{numActiveFilters}</div>}
      </div>
    </>
  );
};

export default Filters;
