/**
@componentName AntiquityFilter
@description Parts filter from antiquity
*/

/** Vendors */
import { useRef } from 'react';
import { func, InferProps } from 'prop-types';

/** Components */
import { Checkbox, Input } from '@/commons';
import FilterContainer from '../shared/container/filter.container';

/** Utils */
import {
  useDispatch,
  useSelector,
  setAntiquityFilter,
  toggleAntiquityFilter,
  toggleFilterCollapse,
} from '@/store';
import { useDelay } from '@/hooks';

/** Styles */
import css from './antiquityFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'antiquity';

const AntiquityFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const antiquity: any = useSelector(state => state.filters.antiquity);

  const run = useDelay();
  const ref: any = useRef();

  const handleOnToggle = () => {
    dispatch(toggleAntiquityFilter());
    ref.current.value = antiquity;
    onFilter && onFilter();
  };

  const handleOnChange = (e: any) => {
    run(() => {
      dispatch(setAntiquityFilter(e.target.value));
      onFilter && onFilter();
    });
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Antiquity</FilterContainer.Title>
        <FilterContainer.Body>
          <div className={css.content}>
            <Checkbox label="ANY" onToggle={handleOnToggle} />
            <Input
              cref={ref}
              type="telephone"
              onChange={handleOnChange}
              style={{ width: '80px' }}
            />
          </div>
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

AntiquityFilter.propTypes = propTypes;
AntiquityFilter.defaultProps = defaultPropTypes;

export default AntiquityFilter;
