import { sass } from '@/config';
import css from './bodyImage.module.scss';

interface Props {
  part: any;
}

const BodyImage = (props: Props) => {
  const { part } = props;

  return (
    <>
      <div className={css['container']}>
        <div className={css['content']}>
          <div
            className={css['elipse']}
            style={{ borderColor: sass['color-rarity-' + part.rarityName] }}
          />
          <img className={css['image']} src={part.imageURL} alt="part" />
        </div>
        <div className={css['rarity']} style={{ color: sass['color-rarity-' + part.rarityName] }}>
          {part.rarityName}
        </div>
      </div>
    </>
  );
};

export default BodyImage;
