import { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import styles from './slider.module.scss';

const Slider = (props: any) => {
  const { currentRange, text, key, color, range, type } = props.data;
  const STEP = 1;
  const MIN = range[0];
  const MAX = range[1];
  const [values, setValues] = useState(currentRange);

  const handleReset = () => {
    props.onFiltered({ type, filtered: { key, values: range } });
    setValues(range);
  };

  const handleOnChange = (filter: { key: string; values: number[] }) => {
    props.onFiltered({ type, filtered: filter });
    setValues(filter.values);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{text}</div>
        <div onClick={handleReset} className={styles.reset}>
          Reset
        </div>
      </div>
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={values => handleOnChange({ key, values })}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', color, '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              width: '12px',
              height: '12px',
              background: '#A1A6B6',
              border: '1px solid #6B7185',
              boxSizing: 'border-box',
              borderRadius: '9999px',
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <div
              style={
                {
                  // height: "0.5rem",
                  // width: "0.5rem",
                  // backgroundColor: isDragged ? color : "#CCC"
                }
              }
            ></div>
          </div>
        )}
      />
      <div className={styles['input-values']}>
        <div className={styles['start']}>
          <div className={`${styles.value}`}>{values[0]}</div>
        </div>
        <div className={styles.separator}>-</div>
        <div className={styles['end']}>
          <div className={`${styles.value}`}>{values[1]}</div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
