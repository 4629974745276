import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { isDesktop } from '@/commons/utils/device';
import css from './banner.module.scss';

// const variants = {
//   visible: { opacity: 1, y: 0 },
//   hidden: { opacity: 0, y: -25 },
// };

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const getResources = (pathname: string) => {
  switch (pathname) {
    case '/marketplace':
      return {
        title: 'marketplace',
      };
    case '/inventory':
      return {
        title: 'inventory',
      };
    case '/users':
      return {
        title: 'user',
      };
    case '/activity':
      return {
        title: 'activity',
      };
    case '/buy':
      return {
        title: 'medamon',
      };
    case '/details':
      return {
        title: 'details',
      };
    case '/profile':
      return {
        title: 'profile',
      };
    default:
      return {
        title: 'marketplace',
      };
  }
};

type Props = {
  fixated: boolean;
  scrollY: number;
};

const Banner = (props: Props) => {
  const { fixated, scrollY } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [resources, setResources] = useState({ title: '' });
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const result = getResources(`/${location.pathname.split('/')[1]}`);
    setResources(result);
  }, [location]);

  useEffect(() => {
    if ((isDesktop() && scrollY > 64) || (!isDesktop() && scrollY > 12)) {
      setHidden(true);
    } else if (scrollY < 48) {
      setHidden(false);
    }
  }, [scrollY]);

  return (
    <>
      <div
        className={`${css.container} ${css[`banner-${resources.title}`]} ${fixated && css.sticky}`}
        style={{ backgroundPositionY: 0.5 * scrollY }}
      >
        <motion.div
          variants={variants}
          animate={hidden ? 'hidden' : 'visible'}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        >
          <div className={css['title-wrapper']}>
            <h1>{t(resources.title)}</h1>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Banner;
