/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  part: any;
  onClose: any;
  onUnlist: Function;
  wallet: string;
};

const RepairModal = (props: Props) => {
  const { isOpen, onClose, onUnlist, part, wallet } = props;
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleRepair = async () => {
    try {
      toast.info('Repairing part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (part.isOnAuction === false) {
        if (wallet) await Web3.unlistPartForSecurity(part.id);
        partEvent(GA_ACTION.UNLIST_FIXED, GA_LABEL.SUCCESS);
      } else {
        console.log('Repairing auction part');
        if (wallet) await Web3.unlistPartAuctionForSecurity(part.id);
        partEvent(GA_ACTION.UNLIST_AUCTION, GA_LABEL.SUCCESS);
      }

      toast.success('Part repaired!', {
        position: 'bottom-right',
        theme: 'colored',
      });
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );
    } finally {
      onUnlist();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`Repair medapart #${part.id}?`}</Modal.Header>
        <Modal.Body>
          {
            <>
              This medapart needs to be repaired to maintain its functionality. If you don't, this medapart
              will perform worse and won't be able to be acquired by other users. Sorry for the
              inconvenience but not everything lasts forever...
              <p style={{fontSize:'12px'}}>** Some cost could be charged depending on the repair work. **</p>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button text="Repair" onClick={handleRepair} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RepairModal;
