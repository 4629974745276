import { Link } from 'react-router-dom';
import { faqLinks } from '@/config';
import css from './faq.module.scss';

const Faq = () => {
  return (
    <div className={css.faq}>
      <ul className={css.ul}>
        {faqLinks.map(route => {
          return route.isExternal ? (
            <li key={route.label} className={css.li}>
              <a
                key={`sitemap_${route.label}`}
                className={css.link}
                href={route.path}
                target="_blank"
                rel="noreferrer"
              >
                {route.label}
              </a>
            </li>
          ) : (
            <li key={route.label} className={css.li}>
              <Link key={`menu_${route.label}`} className={css.link} to={route.path}>
                {route.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Faq;
