import Copyright from './components/copyright/copyright.component';
import Faq from './components/faq/faq.component';
import Sitemap from './components/sitemap/sitemap.component';
import Social from './components/social/social.component';
import css from './footer.module.scss';

const Footer = () => {
  return (
    <>
      <div className={css.footer}>
        <div className={css.left}>
          <div className={css[`left-content`]}>
            <Copyright />
            <Social />
          </div>
          <Sitemap />
        </div>
        <div className={css.right}>
          <Faq />
        </div>
      </div>
    </>
  );
};

export default Footer;
