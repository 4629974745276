/* Utils */
import toast from '@/utils/customToast';
import { Web3 } from '@/services/web3';
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker';
import { Error } from '@/handlers';

/* Components */
import { Button, Modal } from '@/commons';

/* Consts */
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '@/config';

type Props = {
  isOpen: boolean;
  part: any;
  onClose: any;
  onBuy: Function;
  wallet: string;
};

const BuyModal = (props: Props) => {
  const { isOpen, onClose, onBuy, part, wallet } = props;
  const partEvent = useAnalyticsEventTracker(GA_CATEGORY.PART);

  const handleBuy = async () => {
    try {
      toast.info('Buying part...', {
        position: 'bottom-right',
        theme: 'colored',
      });

      if (wallet) await Web3.buyPart(part, wallet);

      toast.success('Part bought!', {
        position: 'bottom-right',
        theme: 'colored',
      });

      partEvent(GA_ACTION.BUY, GA_LABEL.SUCCESS);
    } catch (error: any) {
      toast.error(
        Error.handleError(error?.data?.message || error.message) || Error.defaultError(),
        {
          position: 'bottom-right',
          theme: 'colored',
        }
      );

      partEvent(GA_ACTION.BUY, GA_LABEL.FAILURE);
    } finally {
      onBuy();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{`PURCHASE MEDAPART #${part.id}?`}</Modal.Header>
        <Modal.Body>
          {`Are you sure you want to purchase MEDAPART #${part.id}? You will be redirected to Metamask Wallet to complete the operation.`}
        </Modal.Body>
        <Modal.Footer>
          <Button text="Purchase" onClick={handleBuy} />
          <Button text="Cancel" onClick={onClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyModal;
