import styles from './stats.module.scss';

const statsTypes = [
  {
    name: 'Hp',
    color: '#45FC81',
  },
  {
    name: 'Atk',
    color: '#FEE64C',
  },
  {
    name: 'Def',
    color: '#45B1FC',
  },
  {
    name: 'Spe',
    color: '#FF65D0',
  },
];

const Stats = (props: {
  health: number;
  attack: number;
  defense: number;
  speed: number;
  width?: number;
}) => {
  const { health, attack, defense, speed } = props;

  const getValue = (type: { name: string; color: string }) => {
    switch (type.name) {
      case 'Hp':
        return { value: health, width: (health / 150) * 100 };
      case 'Atk':
        return { value: attack, width: (attack / 150) * 100 };
      case 'Def':
        return { value: defense, width: (defense / 150) * 100 };
      case 'Spe':
        return { value: speed, width: (speed / 150) * 100 };

      default:
        return { value: 0, width: 0 };
    }
  };

  return (
    <div className={styles.stats}>
      {health &&
        statsTypes.map((stat, index) => (
          <div key={`container_${index}`} className={styles.container}>
            <div className={styles.text} style={{ color: stat.color }}>
              {stat.name}
            </div>
            <div className={styles['bar-container']}>
              <div
                className={styles.bar}
                style={{
                  width: `${getValue(stat).width}%`,
                  borderTop: `1.4rem solid ${stat.color}`,
                }}
              />
            </div>
            <div className={styles.value} style={{ color: stat.color }}>
              {getValue(stat).value}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Stats;
