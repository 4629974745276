/**
@componentName EditionFilter
@description Parts filter from edition
*/

/** Vendors */
import { func, InferProps } from 'prop-types';

/** Components */
import FilterContainer from '../shared/container/filter.container';
import { Toggle } from '@/commons';

/** Utils */
import { useDispatch, useSelector, setEditionFilter, toggleFilterCollapse } from '@/store';

/** Constants */
import { EditionFilters } from '@/config';

/** Styles */
import css from './editionFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'edition';

const EditionFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const editionFilter: Array<string> = useSelector(store => store.filters.edition);

  const handleOnToggle = (value: any) => {
    dispatch(setEditionFilter(value));
    onFilter && onFilter();
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Edition</FilterContainer.Title>
        <FilterContainer.Body className={css.content}>
          {EditionFilters.map(
            (filter: { value: string | string[]; text: string | undefined }, idx: number) => (
              <Toggle
                data={filter.value}
                key={idx}
                onToggle={handleOnToggle}
                selected={JSON.stringify(editionFilter) === JSON.stringify(filter.value)}
                text={filter.text}
              />
            )
          )}
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

EditionFilter.propTypes = propTypes;
EditionFilter.defaultProps = defaultPropTypes;

export default EditionFilter;
