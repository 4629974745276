import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import styles from './table.module.scss';

const Table = (props: { data: any[][] }) => {
  const { data } = props;

  const columns = data[0].reduce((acc: any, column: any, idx: number) => {
    if (column) {
      acc.push(
        <th className={styles.th} key={idx} scope="col">
          {column.key}
        </th>
      );
    }
    return acc;
  }, []);

  const table_data: any[] = [];

  data.forEach(row => {
    const content = row.map((value: any, idx: number) => (
      <td className={styles.td} data-label={value.key} key={idx}>
        {value.icon ? (
          <div className={styles.withIcon}>
            <div className={styles.value}>{value.value}</div>
            <div className={styles.icon}>
              {value.value !== '-' && <img src={value.icon} alt="icon" />}
            </div>
          </div>
        ) : (
          value.value
        )}
      </td>
    ));

    table_data.push(
      <tr className={styles.tr} key={Math.random().toString()}>
        {content}
      </tr>
    );
  });

  const getPagination = () => {
    const totalPages = Math.ceil(data.length / 10);

    const pages = [];
    for (let i = 0; i < totalPages; i++) {
      pages.push(<li>{i + 1}</li>);
    }
    return pages;
  };

  const setPage = (e: any) => {
    console.log(e);
  };

  return (
    <div className={styles['table-container']}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr className={styles.tr}>{columns}</tr>
        </thead>
        <tbody className={styles.tbody}>{table_data}</tbody>
      </table>
      <div className={styles.pagination}>
        <LeftOutlined key="left" onClick={setPage} />
        {getPagination()}
        <RightOutlined action="setPage" key="right" onClick={setPage} />
      </div>
    </div>
  );
};

export default Table;
