import { useRef, useState } from 'react';
import { setUser, useDispatch } from '@/store';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../../assets/images/shared/userIcon.png';
import css from './dropdownProfile.module.scss';
import { useClickOutside } from '@/hooks';
import { Web3 } from '@/services/web3';

const DropdownProfile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref: any = useRef();
  useClickOutside(ref, () => setIsVisible(false));

  const handleLogout = () => {
    navigate('/marketplace');
    Web3.clearCacheProvider();
    localStorage.clear();
    dispatch(setUser(''));
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div ref={ref} className={css.container} onClick={handleClick}>
      <img src={UserIcon} alt="user" />
      {isVisible && (
        <div className={css.options}>
          <div onClick={handleLogout} className={css.entry}>
            Log Out
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownProfile;
