import { gql } from '@apollo/client';
import { client } from '../api/graphql';

const tokenParts = `
  query tokenParts (
    $id: Bytes
  ) {
    tokenParts(
      where: {
        id: $id,
        
      },
    ) {
      id
      tokenUri
      creator {
        id
      }
      owner {
        id
      }
      robotName
      partType
      rarityName
      familyName
      imageURL
      attack
      defense
      speed
      health
      price
      isOnSale
      isOnAuction
      isInGame
      edition
      status
      createdAt
      updatedAt
    }
  }`;

export const getPartById = async (id: string) => {
  const result: any = await client.query({
    query: gql(tokenParts),
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data;
};
