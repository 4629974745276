import { combineReducers, configureStore } from '@reduxjs/toolkit';

import balanceReducer from './balance';
import filtersReducer from './filters';
import partsReducer from './parts';
import uiReducer from './ui';
import userReducer from './user';

const rootReducer = combineReducers({
  balance: balanceReducer,
  filters: filtersReducer,
  parts: partsReducer,
  ui: uiReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools:
    process.env.NODE_ENV === 'development'
      ? {
          actionsDenylist: [
            'ui/hideBodyScroll',
            'ui/hideSpinner',
            'ui/showBodyScroll',
            'ui/showSpinner',
          ],
        }
      : false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
