import { useTranslation } from 'react-i18next';

import styles from './notFound.module.scss';

const NotFound = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className={styles['login']}>
      <div className={styles['login-wrapper']}>
        <div className={styles['login-inner']}>
          <div className={styles['login-title']}>{t('notFound')}</div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
