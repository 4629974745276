/**
@componentName StatsFilter
@description Parts filter from stats
*/

/** Vendors */
import { func, InferProps } from 'prop-types';

/** Components */
import FilterContainer from '../shared/container/filter.container';
import Slider from '../shared/slider/slider.component';

/** Utils */
import { useDispatch, useSelector, setStatsFilter, toggleFilterCollapse } from '@/store';
import { useDelay } from '@/hooks';

/** Constants */
import { StatsFilters } from '@/config';

/** Styles */
import css from './statsFilter.module.scss';

/** Types */
const propTypes = {
  onFilter: func,
};

const defaultPropTypes = {};

type Props = InferProps<typeof propTypes>;

const filterName = 'stats';

const StatsFilter = (props: Props) => {
  const { onFilter } = props;
  const dispatch = useDispatch();
  const run = useDelay();
  const filterStats: any = useSelector(store => store.filters.stats);

  const handleOnFilter = (value: any) => {
    run(() => {
      dispatch(setStatsFilter(value.filtered));
      onFilter && onFilter();
    });
  };

  const handleToggleCollapse = () => {
    dispatch(toggleFilterCollapse(filterName));
  };

  return (
    <div className={css.container}>
      <FilterContainer filterName={filterName}>
        <FilterContainer.Title onClick={handleToggleCollapse}>Stats</FilterContainer.Title>
        <FilterContainer.Body className={css.content}>
          <div className={css['center-body']}>
            {StatsFilters.map(filter => (
              <Slider
                data={{ ...filter, currentRange: filterStats[filter.key] }}
                key={filter.key}
                onFiltered={handleOnFilter}
              />
            ))}
          </div>
        </FilterContainer.Body>
      </FilterContainer>
    </div>
  );
};

StatsFilter.propTypes = propTypes;
StatsFilter.defaultProps = defaultPropTypes;

export default StatsFilter;
